(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('ProfileProctorGroups', [], factory);
  } else if (typeof exports === 'object') {
    module.exports = factory();
  } else {
    root.ProfileProctorGroups = factory();
  }
}(this, function() {

  /**
   * Sets proctor groups based on an array of integer values
   * that matches the number of tests in the proctor specification
   * and is in alphabetical order with respect to proctor test names.
   *
   * If no values are supplied, sets default values for all groups.
   *
   * @param {Array.<Object>=} opt_values a list of integers that matches proctor test groups for the request.
   * @private
   * @constructor
   */
    var ProfileProctorGroups_ = function(opt_values) {
        if (opt_values) {
            if (Array.isArray(opt_values)) {
                var testDef;
                testDef = opt_values[0];
                this.career_services_profile_entryValue_ = testDef[0];
                testDef = opt_values[1];
                this.eeodp_profile_tstValue_ = testDef[0];
                testDef = opt_values[2];
                this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ = testDef[0];
                testDef = opt_values[3];
                this.hiredsignal_resume_autocomplete_tstValue_ = testDef[0];
                testDef = opt_values[4];
                this.ibu_india_profile_location_area_field_tstValue_ = testDef[0];
                testDef = opt_values[5];
                this.ibu_india_resume_discovery_users_tstValue_ = testDef[0];
                testDef = opt_values[6];
                this.ibu_jsc_profile_education_tstValue_ = testDef[0];
                testDef = opt_values[7];
                this.ibu_jsc_profile_phone_mandatoryValue_ = testDef[0];
                testDef = opt_values[8];
                this.ibu_jsc_profile_workexp_tstValue_ = testDef[0];
                testDef = opt_values[9];
                this.im_company_blockingValue_ = testDef[0];
                testDef = opt_values[10];
                this.in_apply_resume_builder_educationValue_ = testDef[0];
                testDef = opt_values[11];
                this.in_apply_resume_builder_work_expValue_ = testDef[0];
                testDef = opt_values[12];
                this.in_resume_builder_certificationValue_ = testDef[0];
                testDef = opt_values[13];
                this.in_resume_builder_currentsalaryValue_ = testDef[0];
                testDef = opt_values[14];
                this.in_resume_builder_education_new_fieldsValue_ = testDef[0];
                testDef = opt_values[15];
                this.in_resume_builder_language_proficiency_pageValue_ = testDef[0];
                testDef = opt_values[16];
                this.in_resume_builder_linksValue_ = testDef[0];
                testDef = opt_values[17];
                this.in_resume_builder_work_exp_new_fieldsValue_ = testDef[0];
                testDef = opt_values[18];
                this.intl_non_pdf_searchability_lockValue_ = testDef[0];
                testDef = opt_values[19];
                this.iosnative_rich_profilesValue_ = testDef[0];
                testDef = opt_values[20];
                this.iplus_ig_default_public_resume_builder_tstValue_ = testDef[0];
                testDef = opt_values[21];
                this.jcs_big6_tstValue_ = testDef[0];
                testDef = opt_values[22];
                this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ = testDef[0];
                testDef = opt_values[23];
                this.jpe_crowtags_resume_flow_phase2_tstValue_ = testDef[0];
                testDef = opt_values[24];
                this.jpe_crowtags_resume_flow_tstValue_ = testDef[0];
                testDef = opt_values[25];
                this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ = testDef[0];
                testDef = opt_values[26];
                this.jso_homepage_preferences_surface_factors_data_tstValue_ = testDef[0];
                testDef = opt_values[27];
                this.jso_minimal_onboarding_tstValue_ = testDef[0];
                testDef = opt_values[28];
                this.jso_resume_option_tstValue_ = testDef[0];
                testDef = opt_values[29];
                this.jso_signin_onboarding_tstValue_ = testDef[0];
                testDef = opt_values[30];
                this.jso_skipintro_tstValue_ = testDef[0];
                testDef = opt_values[31];
                this.myind_resume_scan_in_menu_tstValue_ = testDef[0];
                testDef = opt_values[32];
                this.myindallowfileonlytoggleValue_ = testDef[0];
                testDef = opt_values[33];
                this.myindconvertsavedfiletstValue_ = testDef[0];
                this.myindconvertsavedfiletstPayloadValue_ = testDef[1];
                testDef = opt_values[34];
                this.myindmilitaryfieldststValue_ = testDef[0];
                testDef = opt_values[35];
                this.myindresumebuildertstValue_ = testDef[0];
                testDef = opt_values[36];
                this.pet_cpra_donotsellsharelink_tstValue_ = testDef[0];
                testDef = opt_values[37];
                this.powMigrateRenderedResumeMutationTstValue_ = testDef[0];
                testDef = opt_values[38];
                this.pow_askem_survey_hub_tstValue_ = testDef[0];
                testDef = opt_values[39];
                this.pow_askem_survey_tstValue_ = testDef[0];
                testDef = opt_values[40];
                this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ = testDef[0];
                testDef = opt_values[41];
                this.pow_crowtaxo_data_management_frontend_tstValue_ = testDef[0];
                testDef = opt_values[42];
                this.pow_ddrum_page_error_tracking_togValue_ = testDef[0];
                testDef = opt_values[43];
                this.pow_hub_privacy_tstValue_ = testDef[0];
                testDef = opt_values[44];
                this.pow_india_resume_builder_togValue_ = testDef[0];
                testDef = opt_values[45];
                this.pow_invalid_location_prompt_togValue_ = testDef[0];
                testDef = opt_values[46];
                this.pow_jspl_pdf_renderingValue_ = testDef[0];
                testDef = opt_values[47];
                this.pow_multiple_resume_tstValue_ = testDef[0];
                testDef = opt_values[48];
                this.pow_privacy_promo_tstValue_ = testDef[0];
                testDef = opt_values[49];
                this.pow_prof_resume_privacy_tstValue_ = testDef[0];
                testDef = opt_values[50];
                this.pow_resume_education_descriptionValue_ = testDef[0];
                testDef = opt_values[51];
                this.pow_skills_test_deprecationValue_ = testDef[0];
                testDef = opt_values[52];
                this.pow_training_representation_contentValue_ = testDef[0];
                testDef = opt_values[53];
                this.pow_training_representation_fake_doorValue_ = testDef[0];
                testDef = opt_values[54];
                this.pow_years_of_experience_resume_builder_inValue_ = testDef[0];
                testDef = opt_values[55];
                this.powassessmentslinktoggleValue_ = testDef[0];
                testDef = opt_values[56];
                this.powcancelremovebuttonstoggleValue_ = testDef[0];
                testDef = opt_values[57];
                this.powfrommilitaryparametertoggleValue_ = testDef[0];
                testDef = opt_values[58];
                this.powiplphoneinputtstValue_ = testDef[0];
                testDef = opt_values[59];
                this.powmosaicassessmentstoggleValue_ = testDef[0];
                testDef = opt_values[60];
                this.powreviewcompanieslinktoggleValue_ = testDef[0];
                testDef = opt_values[61];
                this.pref_location_resume_builder_inValue_ = testDef[0];
                testDef = opt_values[62];
                this.prof_auto_preferences_schema_tstValue_ = testDef[0];
                testDef = opt_values[63];
                this.prof_auto_resume_sections_schema_tstValue_ = testDef[0];
                testDef = opt_values[64];
                this.prof_back_button_label_toggleValue_ = testDef[0];
                testDef = opt_values[65];
                this.prof_jp_no_thumbnailValue_ = testDef[0];
                testDef = opt_values[66];
                this.prof_keep_file_or_convert_tstValue_ = testDef[0];
                this.prof_keep_file_or_convert_tstPayloadValue_ = testDef[1];
                testDef = opt_values[67];
                this.prof_mvp_data_merge_togValue_ = testDef[0];
                testDef = opt_values[68];
                this.prof_pdf_confirmationValue_ = testDef[0];
                testDef = opt_values[69];
                this.prof_remove_convert_to_indeed_resume_optionValue_ = testDef[0];
                testDef = opt_values[70];
                this.prof_tabbed_expValue_ = testDef[0];
                testDef = opt_values[71];
                this.prof_tabbed_exp_smiley_surveyValue_ = testDef[0];
                testDef = opt_values[72];
                this.prof_upload_file_redaction_tstValue_ = testDef[0];
                testDef = opt_values[73];
                this.profauto_ci_resume_builder_apply_flow_tstValue_ = testDef[0];
                testDef = opt_values[74];
                this.profauto_ci_resume_builder_tstValue_ = testDef[0];
                this.profauto_ci_resume_builder_tstPayloadValue_ = testDef[1];
                testDef = opt_values[75];
                this.profauto_doc_to_pdf_conversion_tstValue_ = testDef[0];
                testDef = opt_values[76];
                this.profauto_hide_pno_email_tstValue_ = testDef[0];
                testDef = opt_values[77];
                this.profauto_increase_postal_coverage_tstValue_ = testDef[0];
                testDef = opt_values[78];
                this.profauto_mc_taxonomy_educationlevelValue_ = testDef[0];
                testDef = opt_values[79];
                this.profauto_minimum_pay_resume_builder_tstValue_ = testDef[0];
                testDef = opt_values[80];
                this.profauto_pow_commute_time_tstValue_ = testDef[0];
                testDef = opt_values[81];
                this.profauto_qualifications_content_tstValue_ = testDef[0];
                testDef = opt_values[82];
                this.profauto_removedeletefromresumeValue_ = testDef[0];
                testDef = opt_values[83];
                this.profile_add_segmentation_preference_togValue_ = testDef[0];
                testDef = opt_values[84];
                this.profile_ai_summary_tstValue_ = testDef[0];
                testDef = opt_values[85];
                this.profile_ai_work_description_tstValue_ = testDef[0];
                testDef = opt_values[86];
                this.profile_enrichment_epas_suggestions_landing_page_togValue_ = testDef[0];
                testDef = opt_values[87];
                this.profile_extracted_qualifications_tstValue_ = testDef[0];
                testDef = opt_values[88];
                this.profile_hub_resumereviewtstValue_ = testDef[0];
                this.profile_hub_resumereviewtstPayloadValue_ = testDef[1];
                testDef = opt_values[89];
                this.profile_js_onboarding_tstValue_ = testDef[0];
                testDef = opt_values[90];
                this.profile_negative_qualifications_tstValue_ = testDef[0];
                testDef = opt_values[91];
                this.profile_one_datadog_rum_toggleValue_ = testDef[0];
                testDef = opt_values[92];
                this.profile_one_fullstory_tstValue_ = testDef[0];
                testDef = opt_values[93];
                this.profile_one_redesign_preferences_tstValue_ = testDef[0];
                testDef = opt_values[94];
                this.profile_pay_ux_updates_tstValue_ = testDef[0];
                testDef = opt_values[95];
                this.profile_photo_disclaimer_text_updateValue_ = testDef[0];
                testDef = opt_values[96];
                this.profile_photos_togValue_ = testDef[0];
                testDef = opt_values[97];
                this.profile_resume_editor_schema_tstValue_ = testDef[0];
                testDef = opt_values[98];
                this.profile_segmentation_preference_togValue_ = testDef[0];
                testDef = opt_values[99];
                this.profile_text_experience_input_tstValue_ = testDef[0];
                testDef = opt_values[100];
                this.profile_update_app_banner_togValue_ = testDef[0];
                testDef = opt_values[101];
                this.profilenosplashwhennameValue_ = testDef[0];
                testDef = opt_values[102];
                this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ = testDef[0];
                testDef = opt_values[103];
                this.re_upload_to_replaceValue_ = testDef[0];
                testDef = opt_values[104];
                this.segment_selection_india_tstValue_ = testDef[0];
                testDef = opt_values[105];
                this.si_profile_education_description_initially_hidden_tstValue_ = testDef[0];
                testDef = opt_values[106];
                this.suggestions_ui_update_flowsValue_ = testDef[0];
                testDef = opt_values[107];
                this.title_match_autocomplete_logging_togValue_ = testDef[0];
            } else {
                var testDef;
                testDef = opt_values["999c9a2b536d1894466f88a9a64ad260b5da9ba363e85d9471f206e3d86438c3"];
                if (!testDef) {
                    this.jcs_big6_tstValue_ = -1;
                } else {
                    this.jcs_big6_tstValue_ = testDef[0];
                }
                testDef = opt_values["318a42bb4242c006d9b3eaabbfd52a680b2019d06f1e3874ebb718d7fcc614f8"];
                if (!testDef) {
                    this.profauto_ci_resume_builder_tstValue_ = -1;
                    this.profauto_ci_resume_builder_tstPayloadValue_ = {};
                } else {
                    this.profauto_ci_resume_builder_tstValue_ = testDef[0];
                    this.profauto_ci_resume_builder_tstPayloadValue_ = testDef[1];
                }
                testDef = opt_values["6de4834960f74f4e66b49e97d1c0d7b924ccd2db80e438d363e91e1a8f93c5c8"];
                if (!testDef) {
                    this.profile_pay_ux_updates_tstValue_ = -1;
                } else {
                    this.profile_pay_ux_updates_tstValue_ = testDef[0];
                }
                testDef = opt_values["4b5fd1b7304f6b24a3b49ebbe91c8ef1cf2f9779f71c4e6771077b0808d236d1"];
                if (!testDef) {
                    this.myind_resume_scan_in_menu_tstValue_ = -1;
                } else {
                    this.myind_resume_scan_in_menu_tstValue_ = testDef[0];
                }
                testDef = opt_values["80cc05edf4cb2607f3ea9aa2039780d5fa99d6a25748dd0b18e0ee8c60982331"];
                if (!testDef) {
                    this.in_resume_builder_certificationValue_ = -1;
                } else {
                    this.in_resume_builder_certificationValue_ = testDef[0];
                }
                testDef = opt_values["3ea08e75e10994e50273cd9d2fcb22ed843e06db1d4e2926564c6fd179a5c939"];
                if (!testDef) {
                    this.prof_keep_file_or_convert_tstValue_ = -1;
                    this.prof_keep_file_or_convert_tstPayloadValue_ = {};
                } else {
                    this.prof_keep_file_or_convert_tstValue_ = testDef[0];
                    this.prof_keep_file_or_convert_tstPayloadValue_ = testDef[1];
                }
                testDef = opt_values["321039c359bcd8d11a9206e305e1c6208bf1236eb95f74938ff1eb45af82c76c"];
                if (!testDef) {
                    this.in_apply_resume_builder_educationValue_ = -1;
                } else {
                    this.in_apply_resume_builder_educationValue_ = testDef[0];
                }
                testDef = opt_values["61104a57002c0978162e6229bb5b6e8510b8649b7687263648d464020ae142f5"];
                if (!testDef) {
                    this.jso_skipintro_tstValue_ = -1;
                } else {
                    this.jso_skipintro_tstValue_ = testDef[0];
                }
                testDef = opt_values["966d949e62627abec586891e56be28964018796ad0ef739d712ad2a37fe380ce"];
                if (!testDef) {
                    this.in_resume_builder_linksValue_ = -1;
                } else {
                    this.in_resume_builder_linksValue_ = testDef[0];
                }
                testDef = opt_values["9347a308297cccb7a88a148d1c298525cc0f856605598a960bb7a3be5082f7dc"];
                if (!testDef) {
                    this.jso_minimal_onboarding_tstValue_ = -1;
                } else {
                    this.jso_minimal_onboarding_tstValue_ = testDef[0];
                }
                testDef = opt_values["6cf0728ab99874b2707350694eb17c5648288f467ee2509114cb4ba2e0cdda12"];
                if (!testDef) {
                    this.pow_skills_test_deprecationValue_ = -1;
                } else {
                    this.pow_skills_test_deprecationValue_ = testDef[0];
                }
                testDef = opt_values["f3dbdc58221648f0809205c969ef1be10c4e6ebe2a2f3ef4593c128c8b14489d"];
                if (!testDef) {
                    this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ = -1;
                } else {
                    this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ = testDef[0];
                }
                testDef = opt_values["a6b2bc3e41fee05a5768349d01f4f0af15c0746c074917d7ea51405ea2e128b8"];
                if (!testDef) {
                    this.profauto_removedeletefromresumeValue_ = -1;
                } else {
                    this.profauto_removedeletefromresumeValue_ = testDef[0];
                }
                testDef = opt_values["a1ea75ef03346991183367f246f9476eaf5fa9a329e527c2a45121c607ec5848"];
                if (!testDef) {
                    this.jso_signin_onboarding_tstValue_ = -1;
                } else {
                    this.jso_signin_onboarding_tstValue_ = testDef[0];
                }
                testDef = opt_values["ef2cf9ae830f32d8dfe0ba13aa7fdc1539b0c3b2668bfa7b86a35eae7cebd272"];
                if (!testDef) {
                    this.myindresumebuildertstValue_ = -1;
                } else {
                    this.myindresumebuildertstValue_ = testDef[0];
                }
                testDef = opt_values["e257beb354906923eb561e6235b26777dcb3db513186721d51c0864a38965b1a"];
                if (!testDef) {
                    this.prof_pdf_confirmationValue_ = -1;
                } else {
                    this.prof_pdf_confirmationValue_ = testDef[0];
                }
                testDef = opt_values["d996452747c772c75c99a7ab118a327a7ca30c31577585f2fe6b2a1b7fdb8490"];
                if (!testDef) {
                    this.myindconvertsavedfiletstValue_ = -1;
                    this.myindconvertsavedfiletstPayloadValue_ = {};
                } else {
                    this.myindconvertsavedfiletstValue_ = testDef[0];
                    this.myindconvertsavedfiletstPayloadValue_ = testDef[1];
                }
                testDef = opt_values["6a42d54536f95f5b90bab3175ac190e2ff0e28d1d83d4db651d24334587bdfd6"];
                if (!testDef) {
                    this.profile_js_onboarding_tstValue_ = -1;
                } else {
                    this.profile_js_onboarding_tstValue_ = testDef[0];
                }
                testDef = opt_values["406cda58f9aa0f63f0a6d1866905d5fb8a3839fe131c4fbb1867b21a9e49cdc5"];
                if (!testDef) {
                    this.jpe_crowtags_resume_flow_tstValue_ = -1;
                } else {
                    this.jpe_crowtags_resume_flow_tstValue_ = testDef[0];
                }
                testDef = opt_values["c625504c8ddce42e43e3bc7a0561700181a5b6c8d6f2f0dd467d9ebc6d3b32f7"];
                if (!testDef) {
                    this.profile_ai_summary_tstValue_ = -1;
                } else {
                    this.profile_ai_summary_tstValue_ = testDef[0];
                }
                testDef = opt_values["885e2df411bddc66924488bf89a4e1aa609706b634c501a14ef589b545125c8e"];
                if (!testDef) {
                    this.jso_resume_option_tstValue_ = -1;
                } else {
                    this.jso_resume_option_tstValue_ = testDef[0];
                }
                testDef = opt_values["d4e29f20fb58eeb1103227c5ff0423affa6a35cf82f2dc019b37450801026f3a"];
                if (!testDef) {
                    this.ibu_jsc_profile_phone_mandatoryValue_ = -1;
                } else {
                    this.ibu_jsc_profile_phone_mandatoryValue_ = testDef[0];
                }
                testDef = opt_values["0e3d1b925356a8c3eef21a9770458105462b8b31beb9d9d093460956ca2abde8"];
                if (!testDef) {
                    this.in_resume_builder_currentsalaryValue_ = -1;
                } else {
                    this.in_resume_builder_currentsalaryValue_ = testDef[0];
                }
                testDef = opt_values["7164e907479b6d1c3800d52995f864fe1cabdfa0d763da600a2a36be9a74544b"];
                if (!testDef) {
                    this.pow_training_representation_contentValue_ = -1;
                } else {
                    this.pow_training_representation_contentValue_ = testDef[0];
                }
                testDef = opt_values["152c599152b285ec197abb07d267542d09b03a37e36b0233f7db2899e7da573b"];
                if (!testDef) {
                    this.prof_auto_preferences_schema_tstValue_ = -1;
                } else {
                    this.prof_auto_preferences_schema_tstValue_ = testDef[0];
                }
                testDef = opt_values["c7dd14a7872d349ef8c65808a0d6d5159a4b8c2df01dc04ebae886347b0f3326"];
                if (!testDef) {
                    this.in_apply_resume_builder_work_expValue_ = -1;
                } else {
                    this.in_apply_resume_builder_work_expValue_ = testDef[0];
                }
                testDef = opt_values["eb549a2ff4d1eab7ed0beed3a17aac06c8bf22ede238d07073fb9d104b342687"];
                if (!testDef) {
                    this.profile_one_redesign_preferences_tstValue_ = -1;
                } else {
                    this.profile_one_redesign_preferences_tstValue_ = testDef[0];
                }
                testDef = opt_values["5394047f80d389cdde77d7916c77364194e747662bd3e9f92737d57780aa8877"];
                if (!testDef) {
                    this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ = -1;
                } else {
                    this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ = testDef[0];
                }
                testDef = opt_values["50a3085b73cf9e584034ac24f563c489db15e9d227e69d22db27bf075db55261"];
                if (!testDef) {
                    this.profile_text_experience_input_tstValue_ = -1;
                } else {
                    this.profile_text_experience_input_tstValue_ = testDef[0];
                }
                testDef = opt_values["5054184b380d66ef1a9da14eb35f6ed093f3aab9bfcd1d07bee83ea0a857be8e"];
                if (!testDef) {
                    this.pow_years_of_experience_resume_builder_inValue_ = -1;
                } else {
                    this.pow_years_of_experience_resume_builder_inValue_ = testDef[0];
                }
                testDef = opt_values["93745fb75d1e1cae5c6804abd82b5f04188f240ecb4a26560da0f3f13b9b1380"];
                if (!testDef) {
                    this.powcancelremovebuttonstoggleValue_ = -1;
                } else {
                    this.powcancelremovebuttonstoggleValue_ = testDef[0];
                }
                testDef = opt_values["a1af7ca1215e787de1d7daa1da8b1f04b7aeeb191e4605645ac8aa727091b643"];
                if (!testDef) {
                    this.ibu_jsc_profile_education_tstValue_ = -1;
                } else {
                    this.ibu_jsc_profile_education_tstValue_ = testDef[0];
                }
                testDef = opt_values["d47de0307e2774cbaa3080f0b658e52cb5d9556e01b25a4179d11c7070c3d2b4"];
                if (!testDef) {
                    this.career_services_profile_entryValue_ = -1;
                } else {
                    this.career_services_profile_entryValue_ = testDef[0];
                }
                testDef = opt_values["2d1c84242b61780cb5c6e1288e3e7306162831a7b387cf9dc27e489af4aedd2b"];
                if (!testDef) {
                    this.si_profile_education_description_initially_hidden_tstValue_ = -1;
                } else {
                    this.si_profile_education_description_initially_hidden_tstValue_ = testDef[0];
                }
                testDef = opt_values["addcb71e6422f6e14c412ca51c44bc3399cdd7d5146c6c5b8eb4e8d7a4486872"];
                if (!testDef) {
                    this.prof_auto_resume_sections_schema_tstValue_ = -1;
                } else {
                    this.prof_auto_resume_sections_schema_tstValue_ = testDef[0];
                }
                testDef = opt_values["b2a5bbd669aaa4c1c3a5dc12f9de986e358dc9c82d6ae7b26524144b9e4d6d29"];
                if (!testDef) {
                    this.powmosaicassessmentstoggleValue_ = -1;
                } else {
                    this.powmosaicassessmentstoggleValue_ = testDef[0];
                }
                testDef = opt_values["94036da13ad2f87b6489e78ba595e40092a377c5c14db743e03186a151166b18"];
                if (!testDef) {
                    this.pow_multiple_resume_tstValue_ = -1;
                } else {
                    this.pow_multiple_resume_tstValue_ = testDef[0];
                }
                testDef = opt_values["656583477f2f7bcb89af3673a7a24a6ed10ef5ccf8e149af1cbcbd5c6ff9ed29"];
                if (!testDef) {
                    this.powassessmentslinktoggleValue_ = -1;
                } else {
                    this.powassessmentslinktoggleValue_ = testDef[0];
                }
                testDef = opt_values["a90610425144f940f9f6e129e69795691ed71125106b9ccd9f11802e7aa13376"];
                if (!testDef) {
                    this.prof_tabbed_expValue_ = -1;
                } else {
                    this.prof_tabbed_expValue_ = testDef[0];
                }
                testDef = opt_values["433eb615cce00e8f80a5291a9240b3d509c1ee058c174d965d74eadc1d8e5f6b"];
                if (!testDef) {
                    this.in_resume_builder_language_proficiency_pageValue_ = -1;
                } else {
                    this.in_resume_builder_language_proficiency_pageValue_ = testDef[0];
                }
                testDef = opt_values["def896796b3ceff62d18ad792283f0ce2505bf1b5c98bb9735a55b52e9ef28ba"];
                if (!testDef) {
                    this.pow_askem_survey_hub_tstValue_ = -1;
                } else {
                    this.pow_askem_survey_hub_tstValue_ = testDef[0];
                }
                testDef = opt_values["de42ca5f044174ced7bf68d349f5ed1cd7ed97970cff4833b0f19642766baf60"];
                if (!testDef) {
                    this.prof_upload_file_redaction_tstValue_ = -1;
                } else {
                    this.prof_upload_file_redaction_tstValue_ = testDef[0];
                }
                testDef = opt_values["50a7535a706bfb01b695b27306c733c3cde9b9531ea3e8cbbb443ddd125752ba"];
                if (!testDef) {
                    this.intl_non_pdf_searchability_lockValue_ = -1;
                } else {
                    this.intl_non_pdf_searchability_lockValue_ = testDef[0];
                }
                testDef = opt_values["d4834ba15c613ac83a180118696b21a5ec4e2c28cb8baacf9ebe78c3b13f66e7"];
                if (!testDef) {
                    this.pow_ddrum_page_error_tracking_togValue_ = -1;
                } else {
                    this.pow_ddrum_page_error_tracking_togValue_ = testDef[0];
                }
                testDef = opt_values["d8524b544dca190e1ccc8ce9922c996f70f55018ddd54fd0ce91da3806e5849b"];
                if (!testDef) {
                    this.prof_jp_no_thumbnailValue_ = -1;
                } else {
                    this.prof_jp_no_thumbnailValue_ = testDef[0];
                }
                testDef = opt_values["05819e5bff314e79973824911b91ba853be519f7f6c24abbb25dc416834769e6"];
                if (!testDef) {
                    this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ = -1;
                } else {
                    this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ = testDef[0];
                }
                testDef = opt_values["d6189977e40053cf39838cfb3180e881a94318eada23438032eff1ba15998021"];
                if (!testDef) {
                    this.myindallowfileonlytoggleValue_ = -1;
                } else {
                    this.myindallowfileonlytoggleValue_ = testDef[0];
                }
                testDef = opt_values["8e390b31e7f64bd603052f66afd73b2cf57f5145b51a5b975e1824cfc2f41196"];
                if (!testDef) {
                    this.pow_prof_resume_privacy_tstValue_ = -1;
                } else {
                    this.pow_prof_resume_privacy_tstValue_ = testDef[0];
                }
                testDef = opt_values["89b08817d64ca181799deb14bc863144fb04e46b3884ea24506c2d83b3561739"];
                if (!testDef) {
                    this.eeodp_profile_tstValue_ = -1;
                } else {
                    this.eeodp_profile_tstValue_ = testDef[0];
                }
                testDef = opt_values["dd301f9c1a9d7bbbae39df52d2aeeb2bf03e5bbb6ed6a51c11a5fec06a5eff57"];
                if (!testDef) {
                    this.profauto_doc_to_pdf_conversion_tstValue_ = -1;
                } else {
                    this.profauto_doc_to_pdf_conversion_tstValue_ = testDef[0];
                }
                testDef = opt_values["b19b9eb3cef83013d7b514728150e1e78f763fa86c2e593861421ac75caba5ee"];
                if (!testDef) {
                    this.pow_hub_privacy_tstValue_ = -1;
                } else {
                    this.pow_hub_privacy_tstValue_ = testDef[0];
                }
                testDef = opt_values["a3e7bae179a59b55f2314a6f0e2f37a48b281c7685fa0284cb2e8e4514daf2cb"];
                if (!testDef) {
                    this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ = -1;
                } else {
                    this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ = testDef[0];
                }
                testDef = opt_values["b68c5a30bd730bf2f24c0ef68828eac410b7392c2374f4a6e3cf1a9ba9c0725c"];
                if (!testDef) {
                    this.re_upload_to_replaceValue_ = -1;
                } else {
                    this.re_upload_to_replaceValue_ = testDef[0];
                }
                testDef = opt_values["c1d20ab5853e06edddd3d0eb356aec05265a63896a6d8b0139ff4c4409003b1d"];
                if (!testDef) {
                    this.profile_one_datadog_rum_toggleValue_ = -1;
                } else {
                    this.profile_one_datadog_rum_toggleValue_ = testDef[0];
                }
                testDef = opt_values["7cdbdf0e9495d7204573a69a90a1e9336ae4d00888a5051a3fa7231360dae16c"];
                if (!testDef) {
                    this.in_resume_builder_work_exp_new_fieldsValue_ = -1;
                } else {
                    this.in_resume_builder_work_exp_new_fieldsValue_ = testDef[0];
                }
                testDef = opt_values["aa9dda246286bae97e769d8f3600abab6186467bfb46fe8492688ffc86eaf391"];
                if (!testDef) {
                    this.profauto_increase_postal_coverage_tstValue_ = -1;
                } else {
                    this.profauto_increase_postal_coverage_tstValue_ = testDef[0];
                }
                testDef = opt_values["c49ab16dbb1541ff761feb7c1876510886d365772fb7c909dbf82050ed0e3cd4"];
                if (!testDef) {
                    this.pet_cpra_donotsellsharelink_tstValue_ = -1;
                } else {
                    this.pet_cpra_donotsellsharelink_tstValue_ = testDef[0];
                }
                testDef = opt_values["dda635bb67ccd95426db42d6c989e1c647c5b21c97a79220bb6f91912113ee94"];
                if (!testDef) {
                    this.prof_tabbed_exp_smiley_surveyValue_ = -1;
                } else {
                    this.prof_tabbed_exp_smiley_surveyValue_ = testDef[0];
                }
                testDef = opt_values["587f633d38f50f4dba31e14df3ada9645cd1d9e3deca5dad45fce044648c11dd"];
                if (!testDef) {
                    this.profauto_ci_resume_builder_apply_flow_tstValue_ = -1;
                } else {
                    this.profauto_ci_resume_builder_apply_flow_tstValue_ = testDef[0];
                }
                testDef = opt_values["9eccbdcc8372c89e8bef7347ac1f1500d093f56c507f7a7aaa959123f7443ad7"];
                if (!testDef) {
                    this.segment_selection_india_tstValue_ = -1;
                } else {
                    this.segment_selection_india_tstValue_ = testDef[0];
                }
                testDef = opt_values["a9d596df732f8987d67a0866fef05355defcc1b9a5ea4ff9e45f908db6cf7d5f"];
                if (!testDef) {
                    this.myindmilitaryfieldststValue_ = -1;
                } else {
                    this.myindmilitaryfieldststValue_ = testDef[0];
                }
                testDef = opt_values["bdb1703f87e9145cd1e1864be130a1dafd259a1c227d7d2a826345d86aa76462"];
                if (!testDef) {
                    this.profile_add_segmentation_preference_togValue_ = -1;
                } else {
                    this.profile_add_segmentation_preference_togValue_ = testDef[0];
                }
                testDef = opt_values["69e611c55ee2ef0334796e29354f04e6fd10ec65ca0164e9a5f1b90b1c7c3741"];
                if (!testDef) {
                    this.profile_hub_resumereviewtstValue_ = -1;
                    this.profile_hub_resumereviewtstPayloadValue_ = {};
                } else {
                    this.profile_hub_resumereviewtstValue_ = testDef[0];
                    this.profile_hub_resumereviewtstPayloadValue_ = testDef[1];
                }
                testDef = opt_values["7861c8b42c6f4b7e6315a785e34cbc6971a71192504c02e38a3fcec9f96e9f81"];
                if (!testDef) {
                    this.powfrommilitaryparametertoggleValue_ = -1;
                } else {
                    this.powfrommilitaryparametertoggleValue_ = testDef[0];
                }
                testDef = opt_values["45a1d47cf2408fc0966e19fe076ca31b6f050bda3951c4f45734f4361077bd35"];
                if (!testDef) {
                    this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ = -1;
                } else {
                    this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ = testDef[0];
                }
                testDef = opt_values["53f77b5871e5f016fd626a093408a2f6d4a4f9242c95cd8f8ce1911d98947ff9"];
                if (!testDef) {
                    this.pow_training_representation_fake_doorValue_ = -1;
                } else {
                    this.pow_training_representation_fake_doorValue_ = testDef[0];
                }
                testDef = opt_values["5ca2ab176d7fe6ca7417c52ee48730c19bd3aaf7bd68204150ef06c931044308"];
                if (!testDef) {
                    this.pow_privacy_promo_tstValue_ = -1;
                } else {
                    this.pow_privacy_promo_tstValue_ = testDef[0];
                }
                testDef = opt_values["6d5fe3bd492ebe5aa1eed94e31b364e48f1f597ab9de8c95115c4f025726e56f"];
                if (!testDef) {
                    this.profauto_mc_taxonomy_educationlevelValue_ = -1;
                } else {
                    this.profauto_mc_taxonomy_educationlevelValue_ = testDef[0];
                }
                testDef = opt_values["c84d4f5fa9ca05b56c0ece20b5cec21ffbd241ca9893781e757085e3a9a6caa5"];
                if (!testDef) {
                    this.profile_one_fullstory_tstValue_ = -1;
                } else {
                    this.profile_one_fullstory_tstValue_ = testDef[0];
                }
                testDef = opt_values["14208579294ec85f48b504feae0b511c620c1503a410c6d4eb680b4b147fa402"];
                if (!testDef) {
                    this.pow_resume_education_descriptionValue_ = -1;
                } else {
                    this.pow_resume_education_descriptionValue_ = testDef[0];
                }
                testDef = opt_values["9e267d04e2ef452fd4fff347a0713432d199f43b9679977125d9a962c6f0a6e6"];
                if (!testDef) {
                    this.powreviewcompanieslinktoggleValue_ = -1;
                } else {
                    this.powreviewcompanieslinktoggleValue_ = testDef[0];
                }
                testDef = opt_values["8604dfba06aafb59029f5e3f9dfc6fcaaeb3bb2abcadcf2d6feb78486af49aa6"];
                if (!testDef) {
                    this.profauto_hide_pno_email_tstValue_ = -1;
                } else {
                    this.profauto_hide_pno_email_tstValue_ = testDef[0];
                }
                testDef = opt_values["b334fe03025d0c80253c637204f819f10094ce1e43ee44e6cddc389b6d3044bd"];
                if (!testDef) {
                    this.profile_negative_qualifications_tstValue_ = -1;
                } else {
                    this.profile_negative_qualifications_tstValue_ = testDef[0];
                }
                testDef = opt_values["9c7a7a92a9feedcb2244d518ce328dc994083ac6c219826f17a7fafa74adf37f"];
                if (!testDef) {
                    this.pow_invalid_location_prompt_togValue_ = -1;
                } else {
                    this.pow_invalid_location_prompt_togValue_ = testDef[0];
                }
                testDef = opt_values["9ddf7970c46a5ba343057f6f9ce257570cde550ef61e0e91e69e1f7496362c84"];
                if (!testDef) {
                    this.ibu_jsc_profile_workexp_tstValue_ = -1;
                } else {
                    this.ibu_jsc_profile_workexp_tstValue_ = testDef[0];
                }
                testDef = opt_values["59bc93d4615d5d2b8c8d3ce6aeb0aac7a5515448800b3f4dc9875d2796b485fb"];
                if (!testDef) {
                    this.hiredsignal_resume_autocomplete_tstValue_ = -1;
                } else {
                    this.hiredsignal_resume_autocomplete_tstValue_ = testDef[0];
                }
                testDef = opt_values["589c5cc0a18fd46d97acf537721c9f8f6efe15d5eb32346d26cea5e5a3bfaf9a"];
                if (!testDef) {
                    this.profile_ai_work_description_tstValue_ = -1;
                } else {
                    this.profile_ai_work_description_tstValue_ = testDef[0];
                }
                testDef = opt_values["f256dd3d228316004532949cd0e6cda140ff8ca5851b30e7c00b44980392e20d"];
                if (!testDef) {
                    this.ibu_india_profile_location_area_field_tstValue_ = -1;
                } else {
                    this.ibu_india_profile_location_area_field_tstValue_ = testDef[0];
                }
                testDef = opt_values["1feb2a001e1c6032fb2b3a095d4213a2542a628b11e962845d0297406ece9547"];
                if (!testDef) {
                    this.ibu_india_resume_discovery_users_tstValue_ = -1;
                } else {
                    this.ibu_india_resume_discovery_users_tstValue_ = testDef[0];
                }
                testDef = opt_values["db44e1193966fe18e0f89cd76647c3e83b217aeb8fab81c49b9be45472433360"];
                if (!testDef) {
                    this.powMigrateRenderedResumeMutationTstValue_ = -1;
                } else {
                    this.powMigrateRenderedResumeMutationTstValue_ = testDef[0];
                }
                testDef = opt_values["c34f8a730dbf1f623a46d52040afb6251b2e6cacaf4be6b410419cc5c5347c80"];
                if (!testDef) {
                    this.profauto_pow_commute_time_tstValue_ = -1;
                } else {
                    this.profauto_pow_commute_time_tstValue_ = testDef[0];
                }
                testDef = opt_values["90c888c7098f853a13a74563f5488d3d09dc3c94700a0fa4a8b4714c0da60c77"];
                if (!testDef) {
                    this.profauto_minimum_pay_resume_builder_tstValue_ = -1;
                } else {
                    this.profauto_minimum_pay_resume_builder_tstValue_ = testDef[0];
                }
                testDef = opt_values["08d71e30809e55795db09e6b9c5e4d0afca031fe0f47329703bc46a595569f37"];
                if (!testDef) {
                    this.suggestions_ui_update_flowsValue_ = -1;
                } else {
                    this.suggestions_ui_update_flowsValue_ = testDef[0];
                }
                testDef = opt_values["8053649ca3c201e0aac81c94d78099c19ea826fcbb3dc181f7e066edae6831a8"];
                if (!testDef) {
                    this.iplus_ig_default_public_resume_builder_tstValue_ = -1;
                } else {
                    this.iplus_ig_default_public_resume_builder_tstValue_ = testDef[0];
                }
                testDef = opt_values["cf16c6e37802b1dd9d32098f2bfb70516f19ea3174052d790582079caf256da5"];
                if (!testDef) {
                    this.title_match_autocomplete_logging_togValue_ = -1;
                } else {
                    this.title_match_autocomplete_logging_togValue_ = testDef[0];
                }
                testDef = opt_values["46622e75336a6b227c1b1cfade23901bc96b42dda1e73da4d8b2614e73630746"];
                if (!testDef) {
                    this.prof_mvp_data_merge_togValue_ = -1;
                } else {
                    this.prof_mvp_data_merge_togValue_ = testDef[0];
                }
                testDef = opt_values["ce49087491454bcad8869df4b80b1339d1b3224674041ae1ac7130eb6ac6d7e3"];
                if (!testDef) {
                    this.profile_extracted_qualifications_tstValue_ = -1;
                } else {
                    this.profile_extracted_qualifications_tstValue_ = testDef[0];
                }
                testDef = opt_values["239f39f24737f890fbf6413af19f7fd2b47bf7266157094504dd11f5fffa8b77"];
                if (!testDef) {
                    this.jpe_crowtags_resume_flow_phase2_tstValue_ = -1;
                } else {
                    this.jpe_crowtags_resume_flow_phase2_tstValue_ = testDef[0];
                }
                testDef = opt_values["901106aa44849192bc5e07504ae513e4deb6df63c98ffbd2ded013831a36e3c8"];
                if (!testDef) {
                    this.prof_back_button_label_toggleValue_ = -1;
                } else {
                    this.prof_back_button_label_toggleValue_ = testDef[0];
                }
                testDef = opt_values["bf989d1305d09b4d3e045a02ad5a37bbcc65dc10c04939bd1bebd1266a0e93e2"];
                if (!testDef) {
                    this.profile_enrichment_epas_suggestions_landing_page_togValue_ = -1;
                } else {
                    this.profile_enrichment_epas_suggestions_landing_page_togValue_ = testDef[0];
                }
                testDef = opt_values["1f69f7baf2b990883d58735318dd3672c7843b39dda71264739faf90f316d0be"];
                if (!testDef) {
                    this.pow_askem_survey_tstValue_ = -1;
                } else {
                    this.pow_askem_survey_tstValue_ = testDef[0];
                }
                testDef = opt_values["ac34f84ac667fd513fbfa5d4a7cf82175a531c41dfe33dae428f1152f9495369"];
                if (!testDef) {
                    this.pow_jspl_pdf_renderingValue_ = -1;
                } else {
                    this.pow_jspl_pdf_renderingValue_ = testDef[0];
                }
                testDef = opt_values["c8122b4e07de4d26eb9ec3bfbcd96e46d901a56e95de63139caa9f8de47ea17f"];
                if (!testDef) {
                    this.profile_resume_editor_schema_tstValue_ = -1;
                } else {
                    this.profile_resume_editor_schema_tstValue_ = testDef[0];
                }
                testDef = opt_values["8db5fd5a1e8a66e9578ca89b727256550ca12ef90ea797c4e3296566fade8b03"];
                if (!testDef) {
                    this.pow_india_resume_builder_togValue_ = -1;
                } else {
                    this.pow_india_resume_builder_togValue_ = testDef[0];
                }
                testDef = opt_values["afc8b3fa67efb2134df66fccd25981c97e8af69e36ec23278a602e3b2a39a94b"];
                if (!testDef) {
                    this.im_company_blockingValue_ = -1;
                } else {
                    this.im_company_blockingValue_ = testDef[0];
                }
                testDef = opt_values["8884be3462d97e4e6faf1c3c8e17cde152a3b0c5d818c73462de503954cd4dfc"];
                if (!testDef) {
                    this.powiplphoneinputtstValue_ = -1;
                } else {
                    this.powiplphoneinputtstValue_ = testDef[0];
                }
                testDef = opt_values["26636867e49724d20b230aff71b5e901fe0ddcd5f09137302447e295b2c38112"];
                if (!testDef) {
                    this.prof_remove_convert_to_indeed_resume_optionValue_ = -1;
                } else {
                    this.prof_remove_convert_to_indeed_resume_optionValue_ = testDef[0];
                }
                testDef = opt_values["28f26e5b53082090721bc5665a3bf97c0328218ca67a4d8ec5427de1def2307b"];
                if (!testDef) {
                    this.profilenosplashwhennameValue_ = -1;
                } else {
                    this.profilenosplashwhennameValue_ = testDef[0];
                }
                testDef = opt_values["b88c6e8c927433cfaed035ca7e8e2e80df45d184d433daf392df5fc86f77e74d"];
                if (!testDef) {
                    this.pref_location_resume_builder_inValue_ = -1;
                } else {
                    this.pref_location_resume_builder_inValue_ = testDef[0];
                }
                testDef = opt_values["84bc0ca930c0e29e388b36caae82d79c72343393941e02acf67611211085c0e3"];
                if (!testDef) {
                    this.in_resume_builder_education_new_fieldsValue_ = -1;
                } else {
                    this.in_resume_builder_education_new_fieldsValue_ = testDef[0];
                }
                testDef = opt_values["c5070a32edbef5587f489890af3cdda35241006a76aef1876a4577ff68247a39"];
                if (!testDef) {
                    this.profile_segmentation_preference_togValue_ = -1;
                } else {
                    this.profile_segmentation_preference_togValue_ = testDef[0];
                }
                testDef = opt_values["8cd959ddae221592df2558e53d90966f72c962889c0d4505327295989f03b1f9"];
                if (!testDef) {
                    this.iosnative_rich_profilesValue_ = -1;
                } else {
                    this.iosnative_rich_profilesValue_ = testDef[0];
                }
                testDef = opt_values["84a643798f1364dc9cf8c2db44d142a49fa4cc1e1091e7329e124fdc25134383"];
                if (!testDef) {
                    this.pow_crowtaxo_data_management_frontend_tstValue_ = -1;
                } else {
                    this.pow_crowtaxo_data_management_frontend_tstValue_ = testDef[0];
                }
                testDef = opt_values["d2bfae1b9ddd65a61c83d620b1d29ffc3c3842abf4beffa0e5da56870e0483b3"];
                if (!testDef) {
                    this.jso_homepage_preferences_surface_factors_data_tstValue_ = -1;
                } else {
                    this.jso_homepage_preferences_surface_factors_data_tstValue_ = testDef[0];
                }
                testDef = opt_values["8d367ed4a8297acb6242ac7026f23d7de4d6a6734b6d182897fd273e46f8a5a6"];
                if (!testDef) {
                    this.profile_photo_disclaimer_text_updateValue_ = -1;
                } else {
                    this.profile_photo_disclaimer_text_updateValue_ = testDef[0];
                }
                testDef = opt_values["62a18dd40f0c12a63951429090c6c9a138891a3fb89755a6a81eb1dd37409a21"];
                if (!testDef) {
                    this.profauto_qualifications_content_tstValue_ = -1;
                } else {
                    this.profauto_qualifications_content_tstValue_ = testDef[0];
                }
                testDef = opt_values["8e4134be7b4ff86bb4bab0357fc6562892f0ec233860a15be100b6794a707d9d"];
                if (!testDef) {
                    this.profile_update_app_banner_togValue_ = -1;
                } else {
                    this.profile_update_app_banner_togValue_ = testDef[0];
                }
                testDef = opt_values["64680ba4b32c5df65822f4a35a1663cea079175aafa0cc8c1500e950d1ce2344"];
                if (!testDef) {
                    this.profile_photos_togValue_ = -1;
                } else {
                    this.profile_photos_togValue_ = testDef[0];
                }
            }
        } else {
            this.career_services_profile_entryValue_ = -1;
            this.eeodp_profile_tstValue_ = -1;
            this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ = -1;
            this.hiredsignal_resume_autocomplete_tstValue_ = -1;
            this.ibu_india_profile_location_area_field_tstValue_ = -1;
            this.ibu_india_resume_discovery_users_tstValue_ = -1;
            this.ibu_jsc_profile_education_tstValue_ = -1;
            this.ibu_jsc_profile_phone_mandatoryValue_ = -1;
            this.ibu_jsc_profile_workexp_tstValue_ = -1;
            this.im_company_blockingValue_ = -1;
            this.in_apply_resume_builder_educationValue_ = -1;
            this.in_apply_resume_builder_work_expValue_ = -1;
            this.in_resume_builder_certificationValue_ = -1;
            this.in_resume_builder_currentsalaryValue_ = -1;
            this.in_resume_builder_education_new_fieldsValue_ = -1;
            this.in_resume_builder_language_proficiency_pageValue_ = -1;
            this.in_resume_builder_linksValue_ = -1;
            this.in_resume_builder_work_exp_new_fieldsValue_ = -1;
            this.intl_non_pdf_searchability_lockValue_ = -1;
            this.iosnative_rich_profilesValue_ = -1;
            this.iplus_ig_default_public_resume_builder_tstValue_ = -1;
            this.jcs_big6_tstValue_ = -1;
            this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ = -1;
            this.jpe_crowtags_resume_flow_phase2_tstValue_ = -1;
            this.jpe_crowtags_resume_flow_tstValue_ = -1;
            this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ = -1;
            this.jso_homepage_preferences_surface_factors_data_tstValue_ = -1;
            this.jso_minimal_onboarding_tstValue_ = -1;
            this.jso_resume_option_tstValue_ = -1;
            this.jso_signin_onboarding_tstValue_ = -1;
            this.jso_skipintro_tstValue_ = -1;
            this.myind_resume_scan_in_menu_tstValue_ = -1;
            this.myindallowfileonlytoggleValue_ = -1;
            this.myindconvertsavedfiletstValue_ = -1;
            this.myindconvertsavedfiletstPayloadValue_ = {};
            this.myindmilitaryfieldststValue_ = -1;
            this.myindresumebuildertstValue_ = -1;
            this.pet_cpra_donotsellsharelink_tstValue_ = -1;
            this.powMigrateRenderedResumeMutationTstValue_ = -1;
            this.pow_askem_survey_hub_tstValue_ = -1;
            this.pow_askem_survey_tstValue_ = -1;
            this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ = -1;
            this.pow_crowtaxo_data_management_frontend_tstValue_ = -1;
            this.pow_ddrum_page_error_tracking_togValue_ = -1;
            this.pow_hub_privacy_tstValue_ = -1;
            this.pow_india_resume_builder_togValue_ = -1;
            this.pow_invalid_location_prompt_togValue_ = -1;
            this.pow_jspl_pdf_renderingValue_ = -1;
            this.pow_multiple_resume_tstValue_ = -1;
            this.pow_privacy_promo_tstValue_ = -1;
            this.pow_prof_resume_privacy_tstValue_ = -1;
            this.pow_resume_education_descriptionValue_ = -1;
            this.pow_skills_test_deprecationValue_ = -1;
            this.pow_training_representation_contentValue_ = -1;
            this.pow_training_representation_fake_doorValue_ = -1;
            this.pow_years_of_experience_resume_builder_inValue_ = -1;
            this.powassessmentslinktoggleValue_ = -1;
            this.powcancelremovebuttonstoggleValue_ = -1;
            this.powfrommilitaryparametertoggleValue_ = -1;
            this.powiplphoneinputtstValue_ = -1;
            this.powmosaicassessmentstoggleValue_ = -1;
            this.powreviewcompanieslinktoggleValue_ = -1;
            this.pref_location_resume_builder_inValue_ = -1;
            this.prof_auto_preferences_schema_tstValue_ = -1;
            this.prof_auto_resume_sections_schema_tstValue_ = -1;
            this.prof_back_button_label_toggleValue_ = -1;
            this.prof_jp_no_thumbnailValue_ = -1;
            this.prof_keep_file_or_convert_tstValue_ = -1;
            this.prof_keep_file_or_convert_tstPayloadValue_ = {};
            this.prof_mvp_data_merge_togValue_ = -1;
            this.prof_pdf_confirmationValue_ = -1;
            this.prof_remove_convert_to_indeed_resume_optionValue_ = -1;
            this.prof_tabbed_expValue_ = -1;
            this.prof_tabbed_exp_smiley_surveyValue_ = -1;
            this.prof_upload_file_redaction_tstValue_ = -1;
            this.profauto_ci_resume_builder_apply_flow_tstValue_ = -1;
            this.profauto_ci_resume_builder_tstValue_ = -1;
            this.profauto_ci_resume_builder_tstPayloadValue_ = {};
            this.profauto_doc_to_pdf_conversion_tstValue_ = -1;
            this.profauto_hide_pno_email_tstValue_ = -1;
            this.profauto_increase_postal_coverage_tstValue_ = -1;
            this.profauto_mc_taxonomy_educationlevelValue_ = -1;
            this.profauto_minimum_pay_resume_builder_tstValue_ = -1;
            this.profauto_pow_commute_time_tstValue_ = -1;
            this.profauto_qualifications_content_tstValue_ = -1;
            this.profauto_removedeletefromresumeValue_ = -1;
            this.profile_add_segmentation_preference_togValue_ = -1;
            this.profile_ai_summary_tstValue_ = -1;
            this.profile_ai_work_description_tstValue_ = -1;
            this.profile_enrichment_epas_suggestions_landing_page_togValue_ = -1;
            this.profile_extracted_qualifications_tstValue_ = -1;
            this.profile_hub_resumereviewtstValue_ = -1;
            this.profile_hub_resumereviewtstPayloadValue_ = {};
            this.profile_js_onboarding_tstValue_ = -1;
            this.profile_negative_qualifications_tstValue_ = -1;
            this.profile_one_datadog_rum_toggleValue_ = -1;
            this.profile_one_fullstory_tstValue_ = -1;
            this.profile_one_redesign_preferences_tstValue_ = -1;
            this.profile_pay_ux_updates_tstValue_ = -1;
            this.profile_photo_disclaimer_text_updateValue_ = -1;
            this.profile_photos_togValue_ = -1;
            this.profile_resume_editor_schema_tstValue_ = -1;
            this.profile_segmentation_preference_togValue_ = -1;
            this.profile_text_experience_input_tstValue_ = -1;
            this.profile_update_app_banner_togValue_ = -1;
            this.profilenosplashwhennameValue_ = -1;
            this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ = -1;
            this.re_upload_to_replaceValue_ = -1;
            this.segment_selection_india_tstValue_ = -1;
            this.si_profile_education_description_initially_hidden_tstValue_ = -1;
            this.suggestions_ui_update_flowsValue_ = -1;
            this.title_match_autocomplete_logging_togValue_ = -1;
        }
    };


  // CAREER_SERVICES_PROFILE_ENTRY


  /**
   * Bucket value for career_services_profile_entry.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.career_services_profile_entryValue_;




  /**
   * Checks if the user is in career_services_profile_entryInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isCareer_services_profile_entryInactive = function() {
    return this.career_services_profile_entryValue_ === -1;
  };


  /**
   * Checks if the user is in career_services_profile_entryControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isCareer_services_profile_entryControl = function() {
    return this.career_services_profile_entryValue_ === 0;
  };


  /**
   * Checks if the user is in career_services_profile_entryTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isCareer_services_profile_entryTest = function() {
    return this.career_services_profile_entryValue_ === 1;
  };



  // EEODP_PROFILE_TST


  /**
   * Bucket value for eeodp_profile_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.eeodp_profile_tstValue_;




  /**
   * Checks if the user is in eeodp_profile_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isEeodp_profile_tstInactive = function() {
    return this.eeodp_profile_tstValue_ === -1;
  };


  /**
   * Checks if the user is in eeodp_profile_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isEeodp_profile_tstActive = function() {
    return this.eeodp_profile_tstValue_ === 1;
  };



  // GNAV_FRONTENDSENTRYJOBSEEKER_PROFILE__PROFILE_ONE_FRONTENDTOG


  /**
   * Bucket value for gnav_frontendsentryjobseeker_profile__profile_one_frontendtog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_;




  /**
   * Checks if the user is in gnav_frontendsentryjobseeker_profile__profile_one_frontendtogInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isGnav_frontendsentryjobseeker_profile__profile_one_frontendtogInactive = function() {
    return this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ === -1;
  };


  /**
   * Checks if the user is in gnav_frontendsentryjobseeker_profile__profile_one_frontendtogControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isGnav_frontendsentryjobseeker_profile__profile_one_frontendtogControl = function() {
    return this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ === 0;
  };


  /**
   * Checks if the user is in gnav_frontendsentryjobseeker_profile__profile_one_frontendtogActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isGnav_frontendsentryjobseeker_profile__profile_one_frontendtogActive = function() {
    return this.gnav_frontendsentryjobseeker_profile__profile_one_frontendtogValue_ === 1;
  };



  // HIREDSIGNAL_RESUME_AUTOCOMPLETE_TST


  /**
   * Bucket value for hiredsignal_resume_autocomplete_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.hiredsignal_resume_autocomplete_tstValue_;




  /**
   * Checks if the user is in hiredsignal_resume_autocomplete_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isHiredsignal_resume_autocomplete_tstInactive = function() {
    return this.hiredsignal_resume_autocomplete_tstValue_ === -1;
  };


  /**
   * Checks if the user is in hiredsignal_resume_autocomplete_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isHiredsignal_resume_autocomplete_tstControl = function() {
    return this.hiredsignal_resume_autocomplete_tstValue_ === 0;
  };


  /**
   * Checks if the user is in hiredsignal_resume_autocomplete_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isHiredsignal_resume_autocomplete_tstTest = function() {
    return this.hiredsignal_resume_autocomplete_tstValue_ === 1;
  };



  // IBU_INDIA_PROFILE_LOCATION_AREA_FIELD_TST


  /**
   * Bucket value for ibu_india_profile_location_area_field_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.ibu_india_profile_location_area_field_tstValue_;




  /**
   * Checks if the user is in ibu_india_profile_location_area_field_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_profile_location_area_field_tstInactive = function() {
    return this.ibu_india_profile_location_area_field_tstValue_ === -1;
  };


  /**
   * Checks if the user is in ibu_india_profile_location_area_field_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_profile_location_area_field_tstControl = function() {
    return this.ibu_india_profile_location_area_field_tstValue_ === 0;
  };


  /**
   * Checks if the user is in ibu_india_profile_location_area_field_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_profile_location_area_field_tstTest = function() {
    return this.ibu_india_profile_location_area_field_tstValue_ === 1;
  };



  // IBU_INDIA_RESUME_DISCOVERY_USERS_TST


  /**
   * Bucket value for ibu_india_resume_discovery_users_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.ibu_india_resume_discovery_users_tstValue_;




  /**
   * Checks if the user is in ibu_india_resume_discovery_users_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_resume_discovery_users_tstInactive = function() {
    return this.ibu_india_resume_discovery_users_tstValue_ === -1;
  };


  /**
   * Checks if the user is in ibu_india_resume_discovery_users_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_resume_discovery_users_tstControl = function() {
    return this.ibu_india_resume_discovery_users_tstValue_ === 0;
  };


  /**
   * Checks if the user is in ibu_india_resume_discovery_users_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_resume_discovery_users_tstTest = function() {
    return this.ibu_india_resume_discovery_users_tstValue_ === 1;
  };


  /**
   * Checks if the user is in ibu_india_resume_discovery_users_tstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_resume_discovery_users_tstGrp2 = function() {
    return this.ibu_india_resume_discovery_users_tstValue_ === 2;
  };


  /**
   * Checks if the user is in ibu_india_resume_discovery_users_tstGrp3.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_resume_discovery_users_tstGrp3 = function() {
    return this.ibu_india_resume_discovery_users_tstValue_ === 3;
  };


  /**
   * Checks if the user is in ibu_india_resume_discovery_users_tstGrp4.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_india_resume_discovery_users_tstGrp4 = function() {
    return this.ibu_india_resume_discovery_users_tstValue_ === 4;
  };



  // IBU_JSC_PROFILE_EDUCATION_TST


  /**
   * Bucket value for ibu_jsc_profile_education_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.ibu_jsc_profile_education_tstValue_;




  /**
   * Checks if the user is in ibu_jsc_profile_education_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_education_tstInactive = function() {
    return this.ibu_jsc_profile_education_tstValue_ === -1;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_education_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_education_tstControl = function() {
    return this.ibu_jsc_profile_education_tstValue_ === 0;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_education_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_education_tstTest = function() {
    return this.ibu_jsc_profile_education_tstValue_ === 1;
  };



  // IBU_JSC_PROFILE_PHONE_MANDATORY


  /**
   * Bucket value for ibu_jsc_profile_phone_mandatory.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.ibu_jsc_profile_phone_mandatoryValue_;




  /**
   * Checks if the user is in ibu_jsc_profile_phone_mandatoryInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_phone_mandatoryInactive = function() {
    return this.ibu_jsc_profile_phone_mandatoryValue_ === -1;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_phone_mandatoryControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_phone_mandatoryControl = function() {
    return this.ibu_jsc_profile_phone_mandatoryValue_ === 0;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_phone_mandatoryTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_phone_mandatoryTest = function() {
    return this.ibu_jsc_profile_phone_mandatoryValue_ === 1;
  };



  // IBU_JSC_PROFILE_WORKEXP_TST


  /**
   * Bucket value for ibu_jsc_profile_workexp_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.ibu_jsc_profile_workexp_tstValue_;




  /**
   * Checks if the user is in ibu_jsc_profile_workexp_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_workexp_tstInactive = function() {
    return this.ibu_jsc_profile_workexp_tstValue_ === -1;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_workexp_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_workexp_tstControl = function() {
    return this.ibu_jsc_profile_workexp_tstValue_ === 0;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_workexp_tstTst1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_workexp_tstTst1 = function() {
    return this.ibu_jsc_profile_workexp_tstValue_ === 1;
  };


  /**
   * Checks if the user is in ibu_jsc_profile_workexp_tstTst2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIbu_jsc_profile_workexp_tstTst2 = function() {
    return this.ibu_jsc_profile_workexp_tstValue_ === 2;
  };



  // IM_COMPANY_BLOCKING


  /**
   * Bucket value for im_company_blocking.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.im_company_blockingValue_;




  /**
   * Checks if the user is in im_company_blockingInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIm_company_blockingInactive = function() {
    return this.im_company_blockingValue_ === -1;
  };


  /**
   * Checks if the user is in im_company_blockingControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIm_company_blockingControl = function() {
    return this.im_company_blockingValue_ === 0;
  };


  /**
   * Checks if the user is in im_company_blockingActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIm_company_blockingActive = function() {
    return this.im_company_blockingValue_ === 1;
  };



  // IN_APPLY_RESUME_BUILDER_EDUCATION


  /**
   * Bucket value for in_apply_resume_builder_education.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_apply_resume_builder_educationValue_;




  /**
   * Checks if the user is in in_apply_resume_builder_educationInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_apply_resume_builder_educationInactive = function() {
    return this.in_apply_resume_builder_educationValue_ === -1;
  };


  /**
   * Checks if the user is in in_apply_resume_builder_educationControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_apply_resume_builder_educationControl = function() {
    return this.in_apply_resume_builder_educationValue_ === 0;
  };


  /**
   * Checks if the user is in in_apply_resume_builder_educationActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_apply_resume_builder_educationActive = function() {
    return this.in_apply_resume_builder_educationValue_ === 1;
  };



  // IN_APPLY_RESUME_BUILDER_WORK_EXP


  /**
   * Bucket value for in_apply_resume_builder_work_exp.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_apply_resume_builder_work_expValue_;




  /**
   * Checks if the user is in in_apply_resume_builder_work_expInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_apply_resume_builder_work_expInactive = function() {
    return this.in_apply_resume_builder_work_expValue_ === -1;
  };


  /**
   * Checks if the user is in in_apply_resume_builder_work_expControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_apply_resume_builder_work_expControl = function() {
    return this.in_apply_resume_builder_work_expValue_ === 0;
  };


  /**
   * Checks if the user is in in_apply_resume_builder_work_expActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_apply_resume_builder_work_expActive = function() {
    return this.in_apply_resume_builder_work_expValue_ === 1;
  };



  // IN_RESUME_BUILDER_CERTIFICATION


  /**
   * Bucket value for in_resume_builder_certification.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_resume_builder_certificationValue_;




  /**
   * Checks if the user is in in_resume_builder_certificationInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_certificationInactive = function() {
    return this.in_resume_builder_certificationValue_ === -1;
  };


  /**
   * Checks if the user is in in_resume_builder_certificationControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_certificationControl = function() {
    return this.in_resume_builder_certificationValue_ === 0;
  };


  /**
   * Checks if the user is in in_resume_builder_certificationGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_certificationGrp1 = function() {
    return this.in_resume_builder_certificationValue_ === 1;
  };



  // IN_RESUME_BUILDER_CURRENTSALARY


  /**
   * Bucket value for in_resume_builder_currentsalary.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_resume_builder_currentsalaryValue_;




  /**
   * Checks if the user is in in_resume_builder_currentsalaryInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_currentsalaryInactive = function() {
    return this.in_resume_builder_currentsalaryValue_ === -1;
  };


  /**
   * Checks if the user is in in_resume_builder_currentsalaryControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_currentsalaryControl = function() {
    return this.in_resume_builder_currentsalaryValue_ === 0;
  };


  /**
   * Checks if the user is in in_resume_builder_currentsalaryActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_currentsalaryActive = function() {
    return this.in_resume_builder_currentsalaryValue_ === 1;
  };



  // IN_RESUME_BUILDER_EDUCATION_NEW_FIELDS


  /**
   * Bucket value for in_resume_builder_education_new_fields.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_resume_builder_education_new_fieldsValue_;




  /**
   * Checks if the user is in in_resume_builder_education_new_fieldsInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_education_new_fieldsInactive = function() {
    return this.in_resume_builder_education_new_fieldsValue_ === -1;
  };


  /**
   * Checks if the user is in in_resume_builder_education_new_fieldsControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_education_new_fieldsControl = function() {
    return this.in_resume_builder_education_new_fieldsValue_ === 0;
  };


  /**
   * Checks if the user is in in_resume_builder_education_new_fieldsGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_education_new_fieldsGrp1 = function() {
    return this.in_resume_builder_education_new_fieldsValue_ === 1;
  };


  /**
   * Checks if the user is in in_resume_builder_education_new_fieldsGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_education_new_fieldsGrp2 = function() {
    return this.in_resume_builder_education_new_fieldsValue_ === 2;
  };



  // IN_RESUME_BUILDER_LANGUAGE_PROFICIENCY_PAGE


  /**
   * Bucket value for in_resume_builder_language_proficiency_page.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_resume_builder_language_proficiency_pageValue_;




  /**
   * Checks if the user is in in_resume_builder_language_proficiency_pageInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_language_proficiency_pageInactive = function() {
    return this.in_resume_builder_language_proficiency_pageValue_ === -1;
  };


  /**
   * Checks if the user is in in_resume_builder_language_proficiency_pageControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_language_proficiency_pageControl = function() {
    return this.in_resume_builder_language_proficiency_pageValue_ === 0;
  };


  /**
   * Checks if the user is in in_resume_builder_language_proficiency_pageGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_language_proficiency_pageGrp1 = function() {
    return this.in_resume_builder_language_proficiency_pageValue_ === 1;
  };


  /**
   * Checks if the user is in in_resume_builder_language_proficiency_pageGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_language_proficiency_pageGrp2 = function() {
    return this.in_resume_builder_language_proficiency_pageValue_ === 2;
  };



  // IN_RESUME_BUILDER_LINKS


  /**
   * Bucket value for in_resume_builder_links.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_resume_builder_linksValue_;




  /**
   * Checks if the user is in in_resume_builder_linksInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_linksInactive = function() {
    return this.in_resume_builder_linksValue_ === -1;
  };


  /**
   * Checks if the user is in in_resume_builder_linksControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_linksControl = function() {
    return this.in_resume_builder_linksValue_ === 0;
  };


  /**
   * Checks if the user is in in_resume_builder_linksGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_linksGrp1 = function() {
    return this.in_resume_builder_linksValue_ === 1;
  };



  // IN_RESUME_BUILDER_WORK_EXP_NEW_FIELDS


  /**
   * Bucket value for in_resume_builder_work_exp_new_fields.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.in_resume_builder_work_exp_new_fieldsValue_;




  /**
   * Checks if the user is in in_resume_builder_work_exp_new_fieldsInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_work_exp_new_fieldsInactive = function() {
    return this.in_resume_builder_work_exp_new_fieldsValue_ === -1;
  };


  /**
   * Checks if the user is in in_resume_builder_work_exp_new_fieldsControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_work_exp_new_fieldsControl = function() {
    return this.in_resume_builder_work_exp_new_fieldsValue_ === 0;
  };


  /**
   * Checks if the user is in in_resume_builder_work_exp_new_fieldsGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_work_exp_new_fieldsGrp1 = function() {
    return this.in_resume_builder_work_exp_new_fieldsValue_ === 1;
  };


  /**
   * Checks if the user is in in_resume_builder_work_exp_new_fieldsGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIn_resume_builder_work_exp_new_fieldsGrp2 = function() {
    return this.in_resume_builder_work_exp_new_fieldsValue_ === 2;
  };



  // INTL_NON_PDF_SEARCHABILITY_LOCK


  /**
   * Bucket value for intl_non_pdf_searchability_lock.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.intl_non_pdf_searchability_lockValue_;




  /**
   * Checks if the user is in intl_non_pdf_searchability_lockInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIntl_non_pdf_searchability_lockInactive = function() {
    return this.intl_non_pdf_searchability_lockValue_ === -1;
  };


  /**
   * Checks if the user is in intl_non_pdf_searchability_lockControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIntl_non_pdf_searchability_lockControl = function() {
    return this.intl_non_pdf_searchability_lockValue_ === 0;
  };


  /**
   * Checks if the user is in intl_non_pdf_searchability_lockActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIntl_non_pdf_searchability_lockActive = function() {
    return this.intl_non_pdf_searchability_lockValue_ === 1;
  };



  // IOSNATIVE_RICH_PROFILES


  /**
   * Bucket value for iosnative_rich_profiles.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.iosnative_rich_profilesValue_;




  /**
   * Checks if the user is in iosnative_rich_profilesInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesInactive = function() {
    return this.iosnative_rich_profilesValue_ === -1;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesControl = function() {
    return this.iosnative_rich_profilesValue_ === 0;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesControl_aa.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesControl_aa = function() {
    return this.iosnative_rich_profilesValue_ === 1;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesPhase_a_active.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesPhase_a_active = function() {
    return this.iosnative_rich_profilesValue_ === 2;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesPhase_a_reserved.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesPhase_a_reserved = function() {
    return this.iosnative_rich_profilesValue_ === 3;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesTaxonomy_fetch_control.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesTaxonomy_fetch_control = function() {
    return this.iosnative_rich_profilesValue_ === 4;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesTaxonomy_fetch.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesTaxonomy_fetch = function() {
    return this.iosnative_rich_profilesValue_ === 5;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesReserved_c_control.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesReserved_c_control = function() {
    return this.iosnative_rich_profilesValue_ === 6;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesReserved_c.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesReserved_c = function() {
    return this.iosnative_rich_profilesValue_ === 7;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesReserved_d_control.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesReserved_d_control = function() {
    return this.iosnative_rich_profilesValue_ === 8;
  };


  /**
   * Checks if the user is in iosnative_rich_profilesReserved_d.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIosnative_rich_profilesReserved_d = function() {
    return this.iosnative_rich_profilesValue_ === 9;
  };



  // IPLUS_IG_DEFAULT_PUBLIC_RESUME_BUILDER_TST


  /**
   * Bucket value for iplus_ig_default_public_resume_builder_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.iplus_ig_default_public_resume_builder_tstValue_;




  /**
   * Checks if the user is in iplus_ig_default_public_resume_builder_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIplus_ig_default_public_resume_builder_tstInactive = function() {
    return this.iplus_ig_default_public_resume_builder_tstValue_ === -1;
  };


  /**
   * Checks if the user is in iplus_ig_default_public_resume_builder_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIplus_ig_default_public_resume_builder_tstControl = function() {
    return this.iplus_ig_default_public_resume_builder_tstValue_ === 0;
  };


  /**
   * Checks if the user is in iplus_ig_default_public_resume_builder_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isIplus_ig_default_public_resume_builder_tstTest = function() {
    return this.iplus_ig_default_public_resume_builder_tstValue_ === 1;
  };



  // JCS_BIG6_TST


  /**
   * Bucket value for jcs_big6_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jcs_big6_tstValue_;




  /**
   * Checks if the user is in jcs_big6_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstInactive = function() {
    return this.jcs_big6_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jcs_big6_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstControl = function() {
    return this.jcs_big6_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jcs_big6_tstVisualChanges.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstVisualChanges = function() {
    return this.jcs_big6_tstValue_ === 1;
  };


  /**
   * Checks if the user is in jcs_big6_tstComboMeta.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstComboMeta = function() {
    return this.jcs_big6_tstValue_ === 2;
  };


  /**
   * Checks if the user is in jcs_big6_tstShifts.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstShifts = function() {
    return this.jcs_big6_tstValue_ === 3;
  };


  /**
   * Checks if the user is in jcs_big6_tstCommute.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstCommute = function() {
    return this.jcs_big6_tstValue_ === 4;
  };


  /**
   * Checks if the user is in jcs_big6_tstScheduleCC.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstScheduleCC = function() {
    return this.jcs_big6_tstValue_ === 5;
  };


  /**
   * Checks if the user is in jcs_big6_tstCommuteAndScheduleCC.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJcs_big6_tstCommuteAndScheduleCC = function() {
    return this.jcs_big6_tstValue_ === 6;
  };



  // JPE_CROWTAGS_RESUME_FLOW_OCCUPATION_AUTOCOMPLETE_TST


  /**
   * Bucket value for jpe_crowtags_resume_flow_occupation_autocomplete_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_;




  /**
   * Checks if the user is in jpe_crowtags_resume_flow_occupation_autocomplete_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_occupation_autocomplete_tstInactive = function() {
    return this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jpe_crowtags_resume_flow_occupation_autocomplete_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_occupation_autocomplete_tstControl = function() {
    return this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jpe_crowtags_resume_flow_occupation_autocomplete_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_occupation_autocomplete_tstActive = function() {
    return this.jpe_crowtags_resume_flow_occupation_autocomplete_tstValue_ === 1;
  };



  // JPE_CROWTAGS_RESUME_FLOW_PHASE2_TST


  /**
   * Bucket value for jpe_crowtags_resume_flow_phase2_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jpe_crowtags_resume_flow_phase2_tstValue_;




  /**
   * Checks if the user is in jpe_crowtags_resume_flow_phase2_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_phase2_tstInactive = function() {
    return this.jpe_crowtags_resume_flow_phase2_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jpe_crowtags_resume_flow_phase2_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_phase2_tstControl = function() {
    return this.jpe_crowtags_resume_flow_phase2_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jpe_crowtags_resume_flow_phase2_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_phase2_tstActive = function() {
    return this.jpe_crowtags_resume_flow_phase2_tstValue_ === 1;
  };



  // JPE_CROWTAGS_RESUME_FLOW_TST


  /**
   * Bucket value for jpe_crowtags_resume_flow_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jpe_crowtags_resume_flow_tstValue_;




  /**
   * Checks if the user is in jpe_crowtags_resume_flow_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_tstInactive = function() {
    return this.jpe_crowtags_resume_flow_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jpe_crowtags_resume_flow_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_tstControl = function() {
    return this.jpe_crowtags_resume_flow_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jpe_crowtags_resume_flow_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJpe_crowtags_resume_flow_tstActive = function() {
    return this.jpe_crowtags_resume_flow_tstValue_ === 1;
  };



  // JSJ_MRP_JCR_JOB_MATCH_PROFILE_POST_RESUME_ENTRYPOINT_TST


  /**
   * Bucket value for jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_;




  /**
   * Checks if the user is in jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstInactive = function() {
    return this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstControl = function() {
    return this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstActive = function() {
    return this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ === 1;
  };


  /**
   * Checks if the user is in jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstGrp2 = function() {
    return this.jsj_mrp_jcr_job_match_profile_post_resume_entrypoint_tstValue_ === 2;
  };



  // JSO_HOMEPAGE_PREFERENCES_SURFACE_FACTORS_DATA_TST


  /**
   * Bucket value for jso_homepage_preferences_surface_factors_data_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jso_homepage_preferences_surface_factors_data_tstValue_;




  /**
   * Checks if the user is in jso_homepage_preferences_surface_factors_data_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_homepage_preferences_surface_factors_data_tstInactive = function() {
    return this.jso_homepage_preferences_surface_factors_data_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jso_homepage_preferences_surface_factors_data_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_homepage_preferences_surface_factors_data_tstControl = function() {
    return this.jso_homepage_preferences_surface_factors_data_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jso_homepage_preferences_surface_factors_data_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_homepage_preferences_surface_factors_data_tstTest = function() {
    return this.jso_homepage_preferences_surface_factors_data_tstValue_ === 1;
  };



  // JSO_MINIMAL_ONBOARDING_TST


  /**
   * Bucket value for jso_minimal_onboarding_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jso_minimal_onboarding_tstValue_;




  /**
   * Checks if the user is in jso_minimal_onboarding_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_minimal_onboarding_tstInactive = function() {
    return this.jso_minimal_onboarding_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jso_minimal_onboarding_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_minimal_onboarding_tstControl = function() {
    return this.jso_minimal_onboarding_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jso_minimal_onboarding_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_minimal_onboarding_tstTest = function() {
    return this.jso_minimal_onboarding_tstValue_ === 1;
  };


  /**
   * Checks if the user is in jso_minimal_onboarding_tstHomepageRedirect.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_minimal_onboarding_tstHomepageRedirect = function() {
    return this.jso_minimal_onboarding_tstValue_ === 2;
  };



  // JSO_RESUME_OPTION_TST


  /**
   * Bucket value for jso_resume_option_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jso_resume_option_tstValue_;




  /**
   * Checks if the user is in jso_resume_option_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_resume_option_tstInactive = function() {
    return this.jso_resume_option_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jso_resume_option_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_resume_option_tstControl = function() {
    return this.jso_resume_option_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jso_resume_option_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_resume_option_tstTest = function() {
    return this.jso_resume_option_tstValue_ === 1;
  };



  // JSO_SIGNIN_ONBOARDING_TST


  /**
   * Bucket value for jso_signin_onboarding_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jso_signin_onboarding_tstValue_;




  /**
   * Checks if the user is in jso_signin_onboarding_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_signin_onboarding_tstInactive = function() {
    return this.jso_signin_onboarding_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jso_signin_onboarding_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_signin_onboarding_tstControl = function() {
    return this.jso_signin_onboarding_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jso_signin_onboarding_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_signin_onboarding_tstTest = function() {
    return this.jso_signin_onboarding_tstValue_ === 1;
  };



  // JSO_SKIPINTRO_TST


  /**
   * Bucket value for jso_skipintro_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.jso_skipintro_tstValue_;




  /**
   * Checks if the user is in jso_skipintro_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_skipintro_tstInactive = function() {
    return this.jso_skipintro_tstValue_ === -1;
  };


  /**
   * Checks if the user is in jso_skipintro_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_skipintro_tstControl = function() {
    return this.jso_skipintro_tstValue_ === 0;
  };


  /**
   * Checks if the user is in jso_skipintro_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isJso_skipintro_tstActive = function() {
    return this.jso_skipintro_tstValue_ === 1;
  };



  // MYIND_RESUME_SCAN_IN_MENU_TST


  /**
   * Bucket value for myind_resume_scan_in_menu_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.myind_resume_scan_in_menu_tstValue_;




  /**
   * Checks if the user is in myind_resume_scan_in_menu_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyind_resume_scan_in_menu_tstInactive = function() {
    return this.myind_resume_scan_in_menu_tstValue_ === -1;
  };


  /**
   * Checks if the user is in myind_resume_scan_in_menu_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyind_resume_scan_in_menu_tstControl = function() {
    return this.myind_resume_scan_in_menu_tstValue_ === 0;
  };


  /**
   * Checks if the user is in myind_resume_scan_in_menu_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyind_resume_scan_in_menu_tstActive = function() {
    return this.myind_resume_scan_in_menu_tstValue_ === 1;
  };



  // MYINDALLOWFILEONLYTOGGLE


  /**
   * Bucket value for myindallowfileonlytoggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.myindallowfileonlytoggleValue_;




  /**
   * Checks if the user is in myindallowfileonlytoggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindallowfileonlytoggleInactive = function() {
    return this.myindallowfileonlytoggleValue_ === -1;
  };


  /**
   * Checks if the user is in myindallowfileonlytoggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindallowfileonlytoggleControl = function() {
    return this.myindallowfileonlytoggleValue_ === 0;
  };


  /**
   * Checks if the user is in myindallowfileonlytoggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindallowfileonlytoggleActive = function() {
    return this.myindallowfileonlytoggleValue_ === 1;
  };


  /**
   * Checks if the user is in myindallowfileonlytoggleDefaultPublic.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindallowfileonlytoggleDefaultPublic = function() {
    return this.myindallowfileonlytoggleValue_ === 2;
  };


  /**
   * Checks if the user is in myindallowfileonlytoggleAutoPublic.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindallowfileonlytoggleAutoPublic = function() {
    return this.myindallowfileonlytoggleValue_ === 3;
  };


  /**
   * Checks if the user is in myindallowfileonlytoggleControlWithPrivacy.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindallowfileonlytoggleControlWithPrivacy = function() {
    return this.myindallowfileonlytoggleValue_ === 4;
  };



  // MYINDCONVERTSAVEDFILETST


  /**
   * Bucket value for myindconvertsavedfiletst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.myindconvertsavedfiletstValue_;


  /**
   * Payload value for myindconvertsavedfiletst.
   * @type {Object.<string, Object>}
   * @private
   */
  ProfileProctorGroups_.prototype.myindconvertsavedfiletstPayloadValue_;


  /**
   * Get the payload value for myindconvertsavedfiletst.
   * @return {Object.<string, Object>}
   */
  ProfileProctorGroups_.prototype.getMyindconvertsavedfiletstPayloadValue = function() {
    return this.myindconvertsavedfiletstPayloadValue_;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstInactive = function() {
    return this.myindconvertsavedfiletstValue_ === -1;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstControl = function() {
    return this.myindconvertsavedfiletstValue_ === 0;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstActive = function() {
    return this.myindconvertsavedfiletstValue_ === 1;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstGrp2 = function() {
    return this.myindconvertsavedfiletstValue_ === 2;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstGrp3.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstGrp3 = function() {
    return this.myindconvertsavedfiletstValue_ === 3;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstGrp4.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstGrp4 = function() {
    return this.myindconvertsavedfiletstValue_ === 4;
  };


  /**
   * Checks if the user is in myindconvertsavedfiletstGrp5.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindconvertsavedfiletstGrp5 = function() {
    return this.myindconvertsavedfiletstValue_ === 5;
  };



  // MYINDMILITARYFIELDSTST


  /**
   * Bucket value for myindmilitaryfieldstst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.myindmilitaryfieldststValue_;




  /**
   * Checks if the user is in myindmilitaryfieldststInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindmilitaryfieldststInactive = function() {
    return this.myindmilitaryfieldststValue_ === -1;
  };


  /**
   * Checks if the user is in myindmilitaryfieldststControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindmilitaryfieldststControl = function() {
    return this.myindmilitaryfieldststValue_ === 0;
  };


  /**
   * Checks if the user is in myindmilitaryfieldststActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindmilitaryfieldststActive = function() {
    return this.myindmilitaryfieldststValue_ === 1;
  };



  // MYINDRESUMEBUILDERTST


  /**
   * Bucket value for myindresumebuildertst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.myindresumebuildertstValue_;




  /**
   * Checks if the user is in myindresumebuildertstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindresumebuildertstInactive = function() {
    return this.myindresumebuildertstValue_ === -1;
  };


  /**
   * Checks if the user is in myindresumebuildertstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindresumebuildertstControl = function() {
    return this.myindresumebuildertstValue_ === 0;
  };


  /**
   * Checks if the user is in myindresumebuildertstGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindresumebuildertstGrp1 = function() {
    return this.myindresumebuildertstValue_ === 1;
  };


  /**
   * Checks if the user is in myindresumebuildertstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindresumebuildertstGrp2 = function() {
    return this.myindresumebuildertstValue_ === 2;
  };


  /**
   * Checks if the user is in myindresumebuildertstGrp3.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isMyindresumebuildertstGrp3 = function() {
    return this.myindresumebuildertstValue_ === 3;
  };



  // PET_CPRA_DONOTSELLSHARELINK_TST


  /**
   * Bucket value for pet_cpra_donotsellsharelink_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pet_cpra_donotsellsharelink_tstValue_;




  /**
   * Checks if the user is in pet_cpra_donotsellsharelink_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPet_cpra_donotsellsharelink_tstInactive = function() {
    return this.pet_cpra_donotsellsharelink_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pet_cpra_donotsellsharelink_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPet_cpra_donotsellsharelink_tstActive = function() {
    return this.pet_cpra_donotsellsharelink_tstValue_ === 1;
  };



  // POW_MIGRATE_RENDERED_RESUME_MUTATION_TST


  /**
   * Bucket value for powMigrateRenderedResumeMutationTst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powMigrateRenderedResumeMutationTstValue_;




  /**
   * Checks if the user is in powMigrateRenderedResumeMutationTstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowMigrateRenderedResumeMutationTstInactive = function() {
    return this.powMigrateRenderedResumeMutationTstValue_ === -1;
  };


  /**
   * Checks if the user is in powMigrateRenderedResumeMutationTstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowMigrateRenderedResumeMutationTstControl = function() {
    return this.powMigrateRenderedResumeMutationTstValue_ === 0;
  };


  /**
   * Checks if the user is in powMigrateRenderedResumeMutationTstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowMigrateRenderedResumeMutationTstTest = function() {
    return this.powMigrateRenderedResumeMutationTstValue_ === 1;
  };



  // POW_ASKEM_SURVEY_HUB_TST


  /**
   * Bucket value for pow_askem_survey_hub_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_askem_survey_hub_tstValue_;




  /**
   * Checks if the user is in pow_askem_survey_hub_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_askem_survey_hub_tstInactive = function() {
    return this.pow_askem_survey_hub_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_askem_survey_hub_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_askem_survey_hub_tstControl = function() {
    return this.pow_askem_survey_hub_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_askem_survey_hub_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_askem_survey_hub_tstTest = function() {
    return this.pow_askem_survey_hub_tstValue_ === 1;
  };



  // POW_ASKEM_SURVEY_TST


  /**
   * Bucket value for pow_askem_survey_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_askem_survey_tstValue_;




  /**
   * Checks if the user is in pow_askem_survey_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_askem_survey_tstInactive = function() {
    return this.pow_askem_survey_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_askem_survey_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_askem_survey_tstControl = function() {
    return this.pow_askem_survey_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_askem_survey_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_askem_survey_tstTest = function() {
    return this.pow_askem_survey_tstValue_ === 1;
  };



  // POW_CREATE_JOB_SEEKER_PROFILE_RESUME_FILE_TEMP_URL_PASSES_FILE_ID


  /**
   * Bucket value for pow_createJobSeekerProfileResumeFileTempUrl_passes_fileId.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_;




  /**
   * Checks if the user is in pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdInactive = function() {
    return this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ === -1;
  };


  /**
   * Checks if the user is in pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdControl = function() {
    return this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ === 0;
  };


  /**
   * Checks if the user is in pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdTest = function() {
    return this.pow_createJobSeekerProfileResumeFileTempUrl_passes_fileIdValue_ === 1;
  };



  // POW_CROWTAXO_DATA_MANAGEMENT_FRONTEND_TST


  /**
   * Bucket value for pow_crowtaxo_data_management_frontend_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_crowtaxo_data_management_frontend_tstValue_;




  /**
   * Checks if the user is in pow_crowtaxo_data_management_frontend_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_crowtaxo_data_management_frontend_tstInactive = function() {
    return this.pow_crowtaxo_data_management_frontend_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_crowtaxo_data_management_frontend_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_crowtaxo_data_management_frontend_tstControl = function() {
    return this.pow_crowtaxo_data_management_frontend_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_crowtaxo_data_management_frontend_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_crowtaxo_data_management_frontend_tstTest = function() {
    return this.pow_crowtaxo_data_management_frontend_tstValue_ === 1;
  };



  // POW_DDRUM_PAGE_ERROR_TRACKING_TOG


  /**
   * Bucket value for pow_ddrum_page_error_tracking_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_ddrum_page_error_tracking_togValue_;




  /**
   * Checks if the user is in pow_ddrum_page_error_tracking_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_ddrum_page_error_tracking_togInactive = function() {
    return this.pow_ddrum_page_error_tracking_togValue_ === -1;
  };


  /**
   * Checks if the user is in pow_ddrum_page_error_tracking_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_ddrum_page_error_tracking_togControl = function() {
    return this.pow_ddrum_page_error_tracking_togValue_ === 0;
  };


  /**
   * Checks if the user is in pow_ddrum_page_error_tracking_togTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_ddrum_page_error_tracking_togTest = function() {
    return this.pow_ddrum_page_error_tracking_togValue_ === 1;
  };



  // POW_HUB_PRIVACY_TST


  /**
   * Bucket value for pow_hub_privacy_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_hub_privacy_tstValue_;




  /**
   * Checks if the user is in pow_hub_privacy_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_hub_privacy_tstInactive = function() {
    return this.pow_hub_privacy_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_hub_privacy_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_hub_privacy_tstControl = function() {
    return this.pow_hub_privacy_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_hub_privacy_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_hub_privacy_tstTest = function() {
    return this.pow_hub_privacy_tstValue_ === 1;
  };



  // POW_INDIA_RESUME_BUILDER_TOG


  /**
   * Bucket value for pow_india_resume_builder_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_india_resume_builder_togValue_;




  /**
   * Checks if the user is in pow_india_resume_builder_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_india_resume_builder_togInactive = function() {
    return this.pow_india_resume_builder_togValue_ === -1;
  };


  /**
   * Checks if the user is in pow_india_resume_builder_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_india_resume_builder_togControl = function() {
    return this.pow_india_resume_builder_togValue_ === 0;
  };


  /**
   * Checks if the user is in pow_india_resume_builder_togTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_india_resume_builder_togTest = function() {
    return this.pow_india_resume_builder_togValue_ === 1;
  };



  // POW_INVALID_LOCATION_PROMPT_TOG


  /**
   * Bucket value for pow_invalid_location_prompt_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_invalid_location_prompt_togValue_;




  /**
   * Checks if the user is in pow_invalid_location_prompt_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_invalid_location_prompt_togInactive = function() {
    return this.pow_invalid_location_prompt_togValue_ === -1;
  };


  /**
   * Checks if the user is in pow_invalid_location_prompt_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_invalid_location_prompt_togControl = function() {
    return this.pow_invalid_location_prompt_togValue_ === 0;
  };


  /**
   * Checks if the user is in pow_invalid_location_prompt_togActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_invalid_location_prompt_togActive = function() {
    return this.pow_invalid_location_prompt_togValue_ === 1;
  };



  // POW_JSPL_PDF_RENDERING


  /**
   * Bucket value for pow_jspl_pdf_rendering.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_jspl_pdf_renderingValue_;




  /**
   * Checks if the user is in pow_jspl_pdf_renderingInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_jspl_pdf_renderingInactive = function() {
    return this.pow_jspl_pdf_renderingValue_ === -1;
  };


  /**
   * Checks if the user is in pow_jspl_pdf_renderingControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_jspl_pdf_renderingControl = function() {
    return this.pow_jspl_pdf_renderingValue_ === 0;
  };


  /**
   * Checks if the user is in pow_jspl_pdf_renderingTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_jspl_pdf_renderingTest = function() {
    return this.pow_jspl_pdf_renderingValue_ === 1;
  };



  // POW_MULTIPLE_RESUME_TST


  /**
   * Bucket value for pow_multiple_resume_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_multiple_resume_tstValue_;




  /**
   * Checks if the user is in pow_multiple_resume_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_multiple_resume_tstInactive = function() {
    return this.pow_multiple_resume_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_multiple_resume_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_multiple_resume_tstControl = function() {
    return this.pow_multiple_resume_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_multiple_resume_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_multiple_resume_tstTest = function() {
    return this.pow_multiple_resume_tstValue_ === 1;
  };



  // POW_PRIVACY_PROMO_TST


  /**
   * Bucket value for pow_privacy_promo_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_privacy_promo_tstValue_;




  /**
   * Checks if the user is in pow_privacy_promo_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_privacy_promo_tstInactive = function() {
    return this.pow_privacy_promo_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_privacy_promo_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_privacy_promo_tstControl = function() {
    return this.pow_privacy_promo_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_privacy_promo_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_privacy_promo_tstActive = function() {
    return this.pow_privacy_promo_tstValue_ === 1;
  };



  // POW_PROF_RESUME_PRIVACY_TST


  /**
   * Bucket value for pow_prof_resume_privacy_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_prof_resume_privacy_tstValue_;




  /**
   * Checks if the user is in pow_prof_resume_privacy_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_prof_resume_privacy_tstInactive = function() {
    return this.pow_prof_resume_privacy_tstValue_ === -1;
  };


  /**
   * Checks if the user is in pow_prof_resume_privacy_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_prof_resume_privacy_tstControl = function() {
    return this.pow_prof_resume_privacy_tstValue_ === 0;
  };


  /**
   * Checks if the user is in pow_prof_resume_privacy_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_prof_resume_privacy_tstTest = function() {
    return this.pow_prof_resume_privacy_tstValue_ === 1;
  };



  // POW_RESUME_EDUCATION_DESCRIPTION


  /**
   * Bucket value for pow_resume_education_description.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_resume_education_descriptionValue_;




  /**
   * Checks if the user is in pow_resume_education_descriptionInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_resume_education_descriptionInactive = function() {
    return this.pow_resume_education_descriptionValue_ === -1;
  };


  /**
   * Checks if the user is in pow_resume_education_descriptionControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_resume_education_descriptionControl = function() {
    return this.pow_resume_education_descriptionValue_ === 0;
  };


  /**
   * Checks if the user is in pow_resume_education_descriptionTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_resume_education_descriptionTest = function() {
    return this.pow_resume_education_descriptionValue_ === 1;
  };



  // POW_SKILLS_TEST_DEPRECATION


  /**
   * Bucket value for pow_skills_test_deprecation.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_skills_test_deprecationValue_;




  /**
   * Checks if the user is in pow_skills_test_deprecationInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_skills_test_deprecationInactive = function() {
    return this.pow_skills_test_deprecationValue_ === -1;
  };


  /**
   * Checks if the user is in pow_skills_test_deprecationControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_skills_test_deprecationControl = function() {
    return this.pow_skills_test_deprecationValue_ === 0;
  };


  /**
   * Checks if the user is in pow_skills_test_deprecationBanner.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_skills_test_deprecationBanner = function() {
    return this.pow_skills_test_deprecationValue_ === 1;
  };


  /**
   * Checks if the user is in pow_skills_test_deprecationTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_skills_test_deprecationTest = function() {
    return this.pow_skills_test_deprecationValue_ === 2;
  };



  // POW_TRAINING_REPRESENTATION_CONTENT


  /**
   * Bucket value for pow_training_representation_content.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_training_representation_contentValue_;




  /**
   * Checks if the user is in pow_training_representation_contentInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_training_representation_contentInactive = function() {
    return this.pow_training_representation_contentValue_ === -1;
  };


  /**
   * Checks if the user is in pow_training_representation_contentControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_training_representation_contentControl = function() {
    return this.pow_training_representation_contentValue_ === 0;
  };


  /**
   * Checks if the user is in pow_training_representation_contentTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_training_representation_contentTest = function() {
    return this.pow_training_representation_contentValue_ === 1;
  };



  // POW_TRAINING_REPRESENTATION_FAKE_DOOR


  /**
   * Bucket value for pow_training_representation_fake_door.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_training_representation_fake_doorValue_;




  /**
   * Checks if the user is in pow_training_representation_fake_doorInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_training_representation_fake_doorInactive = function() {
    return this.pow_training_representation_fake_doorValue_ === -1;
  };


  /**
   * Checks if the user is in pow_training_representation_fake_doorControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_training_representation_fake_doorControl = function() {
    return this.pow_training_representation_fake_doorValue_ === 0;
  };


  /**
   * Checks if the user is in pow_training_representation_fake_doorTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_training_representation_fake_doorTest = function() {
    return this.pow_training_representation_fake_doorValue_ === 1;
  };



  // POW_YEARS_OF_EXPERIENCE_RESUME_BUILDER_IN


  /**
   * Bucket value for pow_years_of_experience_resume_builder_in.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pow_years_of_experience_resume_builder_inValue_;




  /**
   * Checks if the user is in pow_years_of_experience_resume_builder_inInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_years_of_experience_resume_builder_inInactive = function() {
    return this.pow_years_of_experience_resume_builder_inValue_ === -1;
  };


  /**
   * Checks if the user is in pow_years_of_experience_resume_builder_inControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_years_of_experience_resume_builder_inControl = function() {
    return this.pow_years_of_experience_resume_builder_inValue_ === 0;
  };


  /**
   * Checks if the user is in pow_years_of_experience_resume_builder_inGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_years_of_experience_resume_builder_inGrp1 = function() {
    return this.pow_years_of_experience_resume_builder_inValue_ === 1;
  };


  /**
   * Checks if the user is in pow_years_of_experience_resume_builder_inGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPow_years_of_experience_resume_builder_inGrp2 = function() {
    return this.pow_years_of_experience_resume_builder_inValue_ === 2;
  };



  // POWASSESSMENTSLINKTOGGLE


  /**
   * Bucket value for powassessmentslinktoggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powassessmentslinktoggleValue_;




  /**
   * Checks if the user is in powassessmentslinktoggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowassessmentslinktoggleInactive = function() {
    return this.powassessmentslinktoggleValue_ === -1;
  };


  /**
   * Checks if the user is in powassessmentslinktoggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowassessmentslinktoggleControl = function() {
    return this.powassessmentslinktoggleValue_ === 0;
  };


  /**
   * Checks if the user is in powassessmentslinktoggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowassessmentslinktoggleActive = function() {
    return this.powassessmentslinktoggleValue_ === 1;
  };



  // POWCANCELREMOVEBUTTONSTOGGLE


  /**
   * Bucket value for powcancelremovebuttonstoggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powcancelremovebuttonstoggleValue_;




  /**
   * Checks if the user is in powcancelremovebuttonstoggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowcancelremovebuttonstoggleInactive = function() {
    return this.powcancelremovebuttonstoggleValue_ === -1;
  };


  /**
   * Checks if the user is in powcancelremovebuttonstoggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowcancelremovebuttonstoggleControl = function() {
    return this.powcancelremovebuttonstoggleValue_ === 0;
  };


  /**
   * Checks if the user is in powcancelremovebuttonstoggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowcancelremovebuttonstoggleActive = function() {
    return this.powcancelremovebuttonstoggleValue_ === 1;
  };



  // POWFROMMILITARYPARAMETERTOGGLE


  /**
   * Bucket value for powfrommilitaryparametertoggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powfrommilitaryparametertoggleValue_;




  /**
   * Checks if the user is in powfrommilitaryparametertoggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleInactive = function() {
    return this.powfrommilitaryparametertoggleValue_ === -1;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleControl = function() {
    return this.powfrommilitaryparametertoggleValue_ === 0;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_seg_nux.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_seg_nux = function() {
    return this.powfrommilitaryparametertoggleValue_ === 1;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_rdc.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_rdc = function() {
    return this.powfrommilitaryparametertoggleValue_ === 2;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_serp.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_serp = function() {
    return this.powfrommilitaryparametertoggleValue_ === 3;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_cg.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_cg = function() {
    return this.powfrommilitaryparametertoggleValue_ === 4;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_ed.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_ed = function() {
    return this.powfrommilitaryparametertoggleValue_ === 5;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_pr.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_pr = function() {
    return this.powfrommilitaryparametertoggleValue_ === 6;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_sm.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_sm = function() {
    return this.powfrommilitaryparametertoggleValue_ === 7;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_dc.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_dc = function() {
    return this.powfrommilitaryparametertoggleValue_ === 8;
  };


  /**
   * Checks if the user is in powfrommilitaryparametertoggleMilitary_context.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowfrommilitaryparametertoggleMilitary_context = function() {
    return this.powfrommilitaryparametertoggleValue_ === 9;
  };



  // POWIPLPHONEINPUTTST


  /**
   * Bucket value for powiplphoneinputtst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powiplphoneinputtstValue_;




  /**
   * Checks if the user is in powiplphoneinputtstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowiplphoneinputtstInactive = function() {
    return this.powiplphoneinputtstValue_ === -1;
  };


  /**
   * Checks if the user is in powiplphoneinputtstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowiplphoneinputtstControl = function() {
    return this.powiplphoneinputtstValue_ === 0;
  };


  /**
   * Checks if the user is in powiplphoneinputtstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowiplphoneinputtstActive = function() {
    return this.powiplphoneinputtstValue_ === 1;
  };



  // POWMOSAICASSESSMENTSTOGGLE


  /**
   * Bucket value for powmosaicassessmentstoggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powmosaicassessmentstoggleValue_;




  /**
   * Checks if the user is in powmosaicassessmentstoggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowmosaicassessmentstoggleInactive = function() {
    return this.powmosaicassessmentstoggleValue_ === -1;
  };


  /**
   * Checks if the user is in powmosaicassessmentstoggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowmosaicassessmentstoggleControl = function() {
    return this.powmosaicassessmentstoggleValue_ === 0;
  };


  /**
   * Checks if the user is in powmosaicassessmentstoggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowmosaicassessmentstoggleActive = function() {
    return this.powmosaicassessmentstoggleValue_ === 1;
  };



  // POWREVIEWCOMPANIESLINKTOGGLE


  /**
   * Bucket value for powreviewcompanieslinktoggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.powreviewcompanieslinktoggleValue_;




  /**
   * Checks if the user is in powreviewcompanieslinktoggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowreviewcompanieslinktoggleInactive = function() {
    return this.powreviewcompanieslinktoggleValue_ === -1;
  };


  /**
   * Checks if the user is in powreviewcompanieslinktoggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowreviewcompanieslinktoggleControl = function() {
    return this.powreviewcompanieslinktoggleValue_ === 0;
  };


  /**
   * Checks if the user is in powreviewcompanieslinktoggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPowreviewcompanieslinktoggleActive = function() {
    return this.powreviewcompanieslinktoggleValue_ === 1;
  };



  // PREF_LOCATION_RESUME_BUILDER_IN


  /**
   * Bucket value for pref_location_resume_builder_in.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.pref_location_resume_builder_inValue_;




  /**
   * Checks if the user is in pref_location_resume_builder_inInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPref_location_resume_builder_inInactive = function() {
    return this.pref_location_resume_builder_inValue_ === -1;
  };


  /**
   * Checks if the user is in pref_location_resume_builder_inControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPref_location_resume_builder_inControl = function() {
    return this.pref_location_resume_builder_inValue_ === 0;
  };


  /**
   * Checks if the user is in pref_location_resume_builder_inTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isPref_location_resume_builder_inTest = function() {
    return this.pref_location_resume_builder_inValue_ === 1;
  };



  // PROF_AUTO_PREFERENCES_SCHEMA_TST


  /**
   * Bucket value for prof_auto_preferences_schema_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_auto_preferences_schema_tstValue_;




  /**
   * Checks if the user is in prof_auto_preferences_schema_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_auto_preferences_schema_tstInactive = function() {
    return this.prof_auto_preferences_schema_tstValue_ === -1;
  };


  /**
   * Checks if the user is in prof_auto_preferences_schema_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_auto_preferences_schema_tstControl = function() {
    return this.prof_auto_preferences_schema_tstValue_ === 0;
  };


  /**
   * Checks if the user is in prof_auto_preferences_schema_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_auto_preferences_schema_tstActive = function() {
    return this.prof_auto_preferences_schema_tstValue_ === 1;
  };



  // PROF_AUTO_RESUME_SECTIONS_SCHEMA_TST


  /**
   * Bucket value for prof_auto_resume_sections_schema_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_auto_resume_sections_schema_tstValue_;




  /**
   * Checks if the user is in prof_auto_resume_sections_schema_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_auto_resume_sections_schema_tstInactive = function() {
    return this.prof_auto_resume_sections_schema_tstValue_ === -1;
  };


  /**
   * Checks if the user is in prof_auto_resume_sections_schema_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_auto_resume_sections_schema_tstControl = function() {
    return this.prof_auto_resume_sections_schema_tstValue_ === 0;
  };


  /**
   * Checks if the user is in prof_auto_resume_sections_schema_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_auto_resume_sections_schema_tstActive = function() {
    return this.prof_auto_resume_sections_schema_tstValue_ === 1;
  };



  // PROF_BACK_BUTTON_LABEL_TOGGLE


  /**
   * Bucket value for prof_back_button_label_toggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_back_button_label_toggleValue_;




  /**
   * Checks if the user is in prof_back_button_label_toggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_back_button_label_toggleInactive = function() {
    return this.prof_back_button_label_toggleValue_ === -1;
  };


  /**
   * Checks if the user is in prof_back_button_label_toggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_back_button_label_toggleControl = function() {
    return this.prof_back_button_label_toggleValue_ === 0;
  };


  /**
   * Checks if the user is in prof_back_button_label_toggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_back_button_label_toggleActive = function() {
    return this.prof_back_button_label_toggleValue_ === 1;
  };



  // PROF_JP_NO_THUMBNAIL


  /**
   * Bucket value for prof_jp_no_thumbnail.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_jp_no_thumbnailValue_;




  /**
   * Checks if the user is in prof_jp_no_thumbnailInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_jp_no_thumbnailInactive = function() {
    return this.prof_jp_no_thumbnailValue_ === -1;
  };


  /**
   * Checks if the user is in prof_jp_no_thumbnailControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_jp_no_thumbnailControl = function() {
    return this.prof_jp_no_thumbnailValue_ === 0;
  };


  /**
   * Checks if the user is in prof_jp_no_thumbnailTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_jp_no_thumbnailTest = function() {
    return this.prof_jp_no_thumbnailValue_ === 1;
  };



  // PROF_KEEP_FILE_OR_CONVERT_TST


  /**
   * Bucket value for prof_keep_file_or_convert_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_keep_file_or_convert_tstValue_;


  /**
   * Payload value for prof_keep_file_or_convert_tst.
   * @type {Object.<string, Object>}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_keep_file_or_convert_tstPayloadValue_;


  /**
   * Get the payload value for prof_keep_file_or_convert_tst.
   * @return {Object.<string, Object>}
   */
  ProfileProctorGroups_.prototype.getProf_keep_file_or_convert_tstPayloadValue = function() {
    return this.prof_keep_file_or_convert_tstPayloadValue_;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstInactive = function() {
    return this.prof_keep_file_or_convert_tstValue_ === -1;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstControl = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 0;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp1 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 1;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp2 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 2;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp3.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp3 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 3;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp4.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp4 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 4;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp5.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp5 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 5;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp6.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp6 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 6;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp7.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp7 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 7;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp8.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp8 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 8;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp9.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp9 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 9;
  };


  /**
   * Checks if the user is in prof_keep_file_or_convert_tstGrp10.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_keep_file_or_convert_tstGrp10 = function() {
    return this.prof_keep_file_or_convert_tstValue_ === 10;
  };



  // PROF_MVP_DATA_MERGE_TOG


  /**
   * Bucket value for prof_mvp_data_merge_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_mvp_data_merge_togValue_;




  /**
   * Checks if the user is in prof_mvp_data_merge_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_mvp_data_merge_togInactive = function() {
    return this.prof_mvp_data_merge_togValue_ === -1;
  };


  /**
   * Checks if the user is in prof_mvp_data_merge_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_mvp_data_merge_togControl = function() {
    return this.prof_mvp_data_merge_togValue_ === 0;
  };


  /**
   * Checks if the user is in prof_mvp_data_merge_togTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_mvp_data_merge_togTest = function() {
    return this.prof_mvp_data_merge_togValue_ === 1;
  };


  /**
   * Checks if the user is in prof_mvp_data_merge_togDev.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_mvp_data_merge_togDev = function() {
    return this.prof_mvp_data_merge_togValue_ === 2;
  };



  // PROF_PDF_CONFIRMATION


  /**
   * Bucket value for prof_pdf_confirmation.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_pdf_confirmationValue_;




  /**
   * Checks if the user is in prof_pdf_confirmationInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_pdf_confirmationInactive = function() {
    return this.prof_pdf_confirmationValue_ === -1;
  };


  /**
   * Checks if the user is in prof_pdf_confirmationControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_pdf_confirmationControl = function() {
    return this.prof_pdf_confirmationValue_ === 0;
  };


  /**
   * Checks if the user is in prof_pdf_confirmationActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_pdf_confirmationActive = function() {
    return this.prof_pdf_confirmationValue_ === 1;
  };



  // PROF_REMOVE_CONVERT_TO_INDEED_RESUME_OPTION


  /**
   * Bucket value for prof_remove_convert_to_indeed_resume_option.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_remove_convert_to_indeed_resume_optionValue_;




  /**
   * Checks if the user is in prof_remove_convert_to_indeed_resume_optionInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_remove_convert_to_indeed_resume_optionInactive = function() {
    return this.prof_remove_convert_to_indeed_resume_optionValue_ === -1;
  };


  /**
   * Checks if the user is in prof_remove_convert_to_indeed_resume_optionControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_remove_convert_to_indeed_resume_optionControl = function() {
    return this.prof_remove_convert_to_indeed_resume_optionValue_ === 0;
  };


  /**
   * Checks if the user is in prof_remove_convert_to_indeed_resume_optionTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_remove_convert_to_indeed_resume_optionTest = function() {
    return this.prof_remove_convert_to_indeed_resume_optionValue_ === 1;
  };



  // PROF_TABBED_EXP


  /**
   * Bucket value for prof_tabbed_exp.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_tabbed_expValue_;




  /**
   * Checks if the user is in prof_tabbed_expInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_tabbed_expInactive = function() {
    return this.prof_tabbed_expValue_ === -1;
  };


  /**
   * Checks if the user is in prof_tabbed_expControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_tabbed_expControl = function() {
    return this.prof_tabbed_expValue_ === 0;
  };


  /**
   * Checks if the user is in prof_tabbed_expTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_tabbed_expTest = function() {
    return this.prof_tabbed_expValue_ === 1;
  };



  // PROF_TABBED_EXP_SMILEY_SURVEY


  /**
   * Bucket value for prof_tabbed_exp_smiley_survey.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_tabbed_exp_smiley_surveyValue_;




  /**
   * Checks if the user is in prof_tabbed_exp_smiley_surveyInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_tabbed_exp_smiley_surveyInactive = function() {
    return this.prof_tabbed_exp_smiley_surveyValue_ === -1;
  };


  /**
   * Checks if the user is in prof_tabbed_exp_smiley_surveyControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_tabbed_exp_smiley_surveyControl = function() {
    return this.prof_tabbed_exp_smiley_surveyValue_ === 0;
  };


  /**
   * Checks if the user is in prof_tabbed_exp_smiley_surveyTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_tabbed_exp_smiley_surveyTest = function() {
    return this.prof_tabbed_exp_smiley_surveyValue_ === 1;
  };



  // PROF_UPLOAD_FILE_REDACTION_TST


  /**
   * Bucket value for prof_upload_file_redaction_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.prof_upload_file_redaction_tstValue_;




  /**
   * Checks if the user is in prof_upload_file_redaction_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_upload_file_redaction_tstInactive = function() {
    return this.prof_upload_file_redaction_tstValue_ === -1;
  };


  /**
   * Checks if the user is in prof_upload_file_redaction_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_upload_file_redaction_tstControl = function() {
    return this.prof_upload_file_redaction_tstValue_ === 0;
  };


  /**
   * Checks if the user is in prof_upload_file_redaction_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProf_upload_file_redaction_tstActive = function() {
    return this.prof_upload_file_redaction_tstValue_ === 1;
  };



  // PROFAUTO_CI_RESUME_BUILDER_APPLY_FLOW_TST


  /**
   * Bucket value for profauto_ci_resume_builder_apply_flow_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_ci_resume_builder_apply_flow_tstValue_;




  /**
   * Checks if the user is in profauto_ci_resume_builder_apply_flow_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_apply_flow_tstInactive = function() {
    return this.profauto_ci_resume_builder_apply_flow_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_ci_resume_builder_apply_flow_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_apply_flow_tstControl = function() {
    return this.profauto_ci_resume_builder_apply_flow_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_ci_resume_builder_apply_flow_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_apply_flow_tstTest = function() {
    return this.profauto_ci_resume_builder_apply_flow_tstValue_ === 1;
  };



  // PROFAUTO_CI_RESUME_BUILDER_TST


  /**
   * Bucket value for profauto_ci_resume_builder_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_ci_resume_builder_tstValue_;


  /**
   * Payload value for profauto_ci_resume_builder_tst.
   * @type {Object.<string, Object>}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_ci_resume_builder_tstPayloadValue_;


  /**
   * Get the payload value for profauto_ci_resume_builder_tst.
   * @return {Object.<string, Object>}
   */
  ProfileProctorGroups_.prototype.getProfauto_ci_resume_builder_tstPayloadValue = function() {
    return this.profauto_ci_resume_builder_tstPayloadValue_;
  };


  /**
   * Checks if the user is in profauto_ci_resume_builder_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_tstInactive = function() {
    return this.profauto_ci_resume_builder_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_ci_resume_builder_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_tstControl = function() {
    return this.profauto_ci_resume_builder_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_ci_resume_builder_tstGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_tstGrp1 = function() {
    return this.profauto_ci_resume_builder_tstValue_ === 1;
  };


  /**
   * Checks if the user is in profauto_ci_resume_builder_tstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_ci_resume_builder_tstGrp2 = function() {
    return this.profauto_ci_resume_builder_tstValue_ === 2;
  };



  // PROFAUTO_DOC_TO_PDF_CONVERSION_TST


  /**
   * Bucket value for profauto_doc_to_pdf_conversion_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_doc_to_pdf_conversion_tstValue_;




  /**
   * Checks if the user is in profauto_doc_to_pdf_conversion_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_doc_to_pdf_conversion_tstInactive = function() {
    return this.profauto_doc_to_pdf_conversion_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_doc_to_pdf_conversion_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_doc_to_pdf_conversion_tstControl = function() {
    return this.profauto_doc_to_pdf_conversion_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_doc_to_pdf_conversion_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_doc_to_pdf_conversion_tstActive = function() {
    return this.profauto_doc_to_pdf_conversion_tstValue_ === 1;
  };



  // PROFAUTO_HIDE_PNO_EMAIL_TST


  /**
   * Bucket value for profauto_hide_pno_email_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_hide_pno_email_tstValue_;




  /**
   * Checks if the user is in profauto_hide_pno_email_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_hide_pno_email_tstInactive = function() {
    return this.profauto_hide_pno_email_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_hide_pno_email_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_hide_pno_email_tstControl = function() {
    return this.profauto_hide_pno_email_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_hide_pno_email_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_hide_pno_email_tstActive = function() {
    return this.profauto_hide_pno_email_tstValue_ === 1;
  };



  // PROFAUTO_INCREASE_POSTAL_COVERAGE_TST


  /**
   * Bucket value for profauto_increase_postal_coverage_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_increase_postal_coverage_tstValue_;




  /**
   * Checks if the user is in profauto_increase_postal_coverage_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_increase_postal_coverage_tstInactive = function() {
    return this.profauto_increase_postal_coverage_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_increase_postal_coverage_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_increase_postal_coverage_tstControl = function() {
    return this.profauto_increase_postal_coverage_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_increase_postal_coverage_tstGroup1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_increase_postal_coverage_tstGroup1 = function() {
    return this.profauto_increase_postal_coverage_tstValue_ === 1;
  };


  /**
   * Checks if the user is in profauto_increase_postal_coverage_tstGroup2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_increase_postal_coverage_tstGroup2 = function() {
    return this.profauto_increase_postal_coverage_tstValue_ === 2;
  };



  // PROFAUTO_MC_TAXONOMY_EDUCATIONLEVEL


  /**
   * Bucket value for profauto_mc_taxonomy_educationlevel.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_mc_taxonomy_educationlevelValue_;




  /**
   * Checks if the user is in profauto_mc_taxonomy_educationlevelInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_mc_taxonomy_educationlevelInactive = function() {
    return this.profauto_mc_taxonomy_educationlevelValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_mc_taxonomy_educationlevelControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_mc_taxonomy_educationlevelControl = function() {
    return this.profauto_mc_taxonomy_educationlevelValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_mc_taxonomy_educationlevelActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_mc_taxonomy_educationlevelActive = function() {
    return this.profauto_mc_taxonomy_educationlevelValue_ === 1;
  };


  /**
   * Checks if the user is in profauto_mc_taxonomy_educationlevelOpenOnFocus.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_mc_taxonomy_educationlevelOpenOnFocus = function() {
    return this.profauto_mc_taxonomy_educationlevelValue_ === 2;
  };


  /**
   * Checks if the user is in profauto_mc_taxonomy_educationlevelInternational.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_mc_taxonomy_educationlevelInternational = function() {
    return this.profauto_mc_taxonomy_educationlevelValue_ === 3;
  };



  // PROFAUTO_MINIMUM_PAY_RESUME_BUILDER_TST


  /**
   * Bucket value for profauto_minimum_pay_resume_builder_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_minimum_pay_resume_builder_tstValue_;




  /**
   * Checks if the user is in profauto_minimum_pay_resume_builder_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_minimum_pay_resume_builder_tstInactive = function() {
    return this.profauto_minimum_pay_resume_builder_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_minimum_pay_resume_builder_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_minimum_pay_resume_builder_tstControl = function() {
    return this.profauto_minimum_pay_resume_builder_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_minimum_pay_resume_builder_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_minimum_pay_resume_builder_tstActive = function() {
    return this.profauto_minimum_pay_resume_builder_tstValue_ === 1;
  };



  // PROFAUTO_POW_COMMUTE_TIME_TST


  /**
   * Bucket value for profauto_pow_commute_time_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_pow_commute_time_tstValue_;




  /**
   * Checks if the user is in profauto_pow_commute_time_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_pow_commute_time_tstInactive = function() {
    return this.profauto_pow_commute_time_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_pow_commute_time_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_pow_commute_time_tstControl = function() {
    return this.profauto_pow_commute_time_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_pow_commute_time_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_pow_commute_time_tstActive = function() {
    return this.profauto_pow_commute_time_tstValue_ === 1;
  };



  // PROFAUTO_QUALIFICATIONS_CONTENT_TST


  /**
   * Bucket value for profauto_qualifications_content_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_qualifications_content_tstValue_;




  /**
   * Checks if the user is in profauto_qualifications_content_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_qualifications_content_tstInactive = function() {
    return this.profauto_qualifications_content_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_qualifications_content_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_qualifications_content_tstControl = function() {
    return this.profauto_qualifications_content_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_qualifications_content_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_qualifications_content_tstActive = function() {
    return this.profauto_qualifications_content_tstValue_ === 1;
  };



  // PROFAUTO_REMOVEDELETEFROMRESUME


  /**
   * Bucket value for profauto_removedeletefromresume.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profauto_removedeletefromresumeValue_;




  /**
   * Checks if the user is in profauto_removedeletefromresumeInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_removedeletefromresumeInactive = function() {
    return this.profauto_removedeletefromresumeValue_ === -1;
  };


  /**
   * Checks if the user is in profauto_removedeletefromresumeControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_removedeletefromresumeControl = function() {
    return this.profauto_removedeletefromresumeValue_ === 0;
  };


  /**
   * Checks if the user is in profauto_removedeletefromresumeActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfauto_removedeletefromresumeActive = function() {
    return this.profauto_removedeletefromresumeValue_ === 1;
  };



  // PROFILE_ADD_SEGMENTATION_PREFERENCE_TOG


  /**
   * Bucket value for profile_add_segmentation_preference_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_add_segmentation_preference_togValue_;




  /**
   * Checks if the user is in profile_add_segmentation_preference_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_add_segmentation_preference_togInactive = function() {
    return this.profile_add_segmentation_preference_togValue_ === -1;
  };


  /**
   * Checks if the user is in profile_add_segmentation_preference_togGrp0.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_add_segmentation_preference_togGrp0 = function() {
    return this.profile_add_segmentation_preference_togValue_ === 0;
  };


  /**
   * Checks if the user is in profile_add_segmentation_preference_togGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_add_segmentation_preference_togGrp1 = function() {
    return this.profile_add_segmentation_preference_togValue_ === 1;
  };



  // PROFILE_AI_SUMMARY_TST


  /**
   * Bucket value for profile_ai_summary_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_ai_summary_tstValue_;




  /**
   * Checks if the user is in profile_ai_summary_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_summary_tstInactive = function() {
    return this.profile_ai_summary_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_ai_summary_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_summary_tstControl = function() {
    return this.profile_ai_summary_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_ai_summary_tstPenguin.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_summary_tstPenguin = function() {
    return this.profile_ai_summary_tstValue_ === 1;
  };


  /**
   * Checks if the user is in profile_ai_summary_tstGpt4omini.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_summary_tstGpt4omini = function() {
    return this.profile_ai_summary_tstValue_ === 2;
  };


  /**
   * Checks if the user is in profile_ai_summary_tstGpt4o.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_summary_tstGpt4o = function() {
    return this.profile_ai_summary_tstValue_ === 3;
  };



  // PROFILE_AI_WORK_DESCRIPTION_TST


  /**
   * Bucket value for profile_ai_work_description_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_ai_work_description_tstValue_;




  /**
   * Checks if the user is in profile_ai_work_description_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_work_description_tstInactive = function() {
    return this.profile_ai_work_description_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_ai_work_description_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_work_description_tstControl = function() {
    return this.profile_ai_work_description_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_ai_work_description_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_ai_work_description_tstTest = function() {
    return this.profile_ai_work_description_tstValue_ === 1;
  };



  // PROFILE_ENRICHMENT_EPAS_SUGGESTIONS_LANDING_PAGE_TOG


  /**
   * Bucket value for profile_enrichment_epas_suggestions_landing_page_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_enrichment_epas_suggestions_landing_page_togValue_;




  /**
   * Checks if the user is in profile_enrichment_epas_suggestions_landing_page_togDisabled.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_enrichment_epas_suggestions_landing_page_togDisabled = function() {
    return this.profile_enrichment_epas_suggestions_landing_page_togValue_ === -1;
  };


  /**
   * Checks if the user is in profile_enrichment_epas_suggestions_landing_page_togEnabled.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_enrichment_epas_suggestions_landing_page_togEnabled = function() {
    return this.profile_enrichment_epas_suggestions_landing_page_togValue_ === 1;
  };



  // PROFILE_EXTRACTED_QUALIFICATIONS_TST


  /**
   * Bucket value for profile_extracted_qualifications_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_extracted_qualifications_tstValue_;




  /**
   * Checks if the user is in profile_extracted_qualifications_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_extracted_qualifications_tstInactive = function() {
    return this.profile_extracted_qualifications_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_extracted_qualifications_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_extracted_qualifications_tstControl = function() {
    return this.profile_extracted_qualifications_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_extracted_qualifications_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_extracted_qualifications_tstActive = function() {
    return this.profile_extracted_qualifications_tstValue_ === 1;
  };



  // PROFILE_HUB_RESUMEREVIEWTST


  /**
   * Bucket value for profile_hub_resumereviewtst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_hub_resumereviewtstValue_;


  /**
   * Payload value for profile_hub_resumereviewtst.
   * @type {Object.<string, Object>}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_hub_resumereviewtstPayloadValue_;


  /**
   * Get the payload value for profile_hub_resumereviewtst.
   * @return {Object.<string, Object>}
   */
  ProfileProctorGroups_.prototype.getProfile_hub_resumereviewtstPayloadValue = function() {
    return this.profile_hub_resumereviewtstPayloadValue_;
  };


  /**
   * Checks if the user is in profile_hub_resumereviewtstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_hub_resumereviewtstInactive = function() {
    return this.profile_hub_resumereviewtstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_hub_resumereviewtstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_hub_resumereviewtstControl = function() {
    return this.profile_hub_resumereviewtstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_hub_resumereviewtstGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_hub_resumereviewtstGrp1 = function() {
    return this.profile_hub_resumereviewtstValue_ === 1;
  };


  /**
   * Checks if the user is in profile_hub_resumereviewtstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_hub_resumereviewtstGrp2 = function() {
    return this.profile_hub_resumereviewtstValue_ === 2;
  };


  /**
   * Checks if the user is in profile_hub_resumereviewtstGrp3.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_hub_resumereviewtstGrp3 = function() {
    return this.profile_hub_resumereviewtstValue_ === 3;
  };



  // PROFILE_JS_ONBOARDING_TST


  /**
   * Bucket value for profile_js_onboarding_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_js_onboarding_tstValue_;




  /**
   * Checks if the user is in profile_js_onboarding_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_js_onboarding_tstInactive = function() {
    return this.profile_js_onboarding_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_js_onboarding_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_js_onboarding_tstControl = function() {
    return this.profile_js_onboarding_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_js_onboarding_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_js_onboarding_tstActive = function() {
    return this.profile_js_onboarding_tstValue_ === 1;
  };


  /**
   * Checks if the user is in profile_js_onboarding_tstSchemas.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_js_onboarding_tstSchemas = function() {
    return this.profile_js_onboarding_tstValue_ === 2;
  };



  // PROFILE_NEGATIVE_QUALIFICATIONS_TST


  /**
   * Bucket value for profile_negative_qualifications_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_negative_qualifications_tstValue_;




  /**
   * Checks if the user is in profile_negative_qualifications_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_negative_qualifications_tstInactive = function() {
    return this.profile_negative_qualifications_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_negative_qualifications_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_negative_qualifications_tstControl = function() {
    return this.profile_negative_qualifications_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_negative_qualifications_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_negative_qualifications_tstActive = function() {
    return this.profile_negative_qualifications_tstValue_ === 1;
  };



  // PROFILE_ONE_DATADOG_RUM_TOGGLE


  /**
   * Bucket value for profile_one_datadog_rum_toggle.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_one_datadog_rum_toggleValue_;




  /**
   * Checks if the user is in profile_one_datadog_rum_toggleInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_datadog_rum_toggleInactive = function() {
    return this.profile_one_datadog_rum_toggleValue_ === -1;
  };


  /**
   * Checks if the user is in profile_one_datadog_rum_toggleControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_datadog_rum_toggleControl = function() {
    return this.profile_one_datadog_rum_toggleValue_ === 0;
  };


  /**
   * Checks if the user is in profile_one_datadog_rum_toggleActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_datadog_rum_toggleActive = function() {
    return this.profile_one_datadog_rum_toggleValue_ === 1;
  };



  // PROFILE_ONE_FULLSTORY_TST


  /**
   * Bucket value for profile_one_fullstory_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_one_fullstory_tstValue_;




  /**
   * Checks if the user is in profile_one_fullstory_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_fullstory_tstInactive = function() {
    return this.profile_one_fullstory_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_one_fullstory_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_fullstory_tstControl = function() {
    return this.profile_one_fullstory_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_one_fullstory_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_fullstory_tstActive = function() {
    return this.profile_one_fullstory_tstValue_ === 1;
  };



  // PROFILE_ONE_REDESIGN_PREFERENCES_TST


  /**
   * Bucket value for profile_one_redesign_preferences_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_one_redesign_preferences_tstValue_;




  /**
   * Checks if the user is in profile_one_redesign_preferences_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_redesign_preferences_tstInactive = function() {
    return this.profile_one_redesign_preferences_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_one_redesign_preferences_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_redesign_preferences_tstControl = function() {
    return this.profile_one_redesign_preferences_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_one_redesign_preferences_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_one_redesign_preferences_tstActive = function() {
    return this.profile_one_redesign_preferences_tstValue_ === 1;
  };



  // PROFILE_PAY_UX_UPDATES_TST


  /**
   * Bucket value for profile_pay_ux_updates_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_pay_ux_updates_tstValue_;




  /**
   * Checks if the user is in profile_pay_ux_updates_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_pay_ux_updates_tstInactive = function() {
    return this.profile_pay_ux_updates_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_pay_ux_updates_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_pay_ux_updates_tstControl = function() {
    return this.profile_pay_ux_updates_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_pay_ux_updates_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_pay_ux_updates_tstTest = function() {
    return this.profile_pay_ux_updates_tstValue_ === 1;
  };



  // PROFILE_PHOTO_DISCLAIMER_TEXT_UPDATE


  /**
   * Bucket value for profile_photo_disclaimer_text_update.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_photo_disclaimer_text_updateValue_;




  /**
   * Checks if the user is in profile_photo_disclaimer_text_updateInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_photo_disclaimer_text_updateInactive = function() {
    return this.profile_photo_disclaimer_text_updateValue_ === -1;
  };


  /**
   * Checks if the user is in profile_photo_disclaimer_text_updateControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_photo_disclaimer_text_updateControl = function() {
    return this.profile_photo_disclaimer_text_updateValue_ === 0;
  };


  /**
   * Checks if the user is in profile_photo_disclaimer_text_updateActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_photo_disclaimer_text_updateActive = function() {
    return this.profile_photo_disclaimer_text_updateValue_ === 1;
  };



  // PROFILE_PHOTOS_TOG


  /**
   * Bucket value for profile_photos_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_photos_togValue_;




  /**
   * Checks if the user is in profile_photos_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_photos_togInactive = function() {
    return this.profile_photos_togValue_ === -1;
  };


  /**
   * Checks if the user is in profile_photos_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_photos_togControl = function() {
    return this.profile_photos_togValue_ === 0;
  };


  /**
   * Checks if the user is in profile_photos_togActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_photos_togActive = function() {
    return this.profile_photos_togValue_ === 1;
  };



  // PROFILE_RESUME_EDITOR_SCHEMA_TST


  /**
   * Bucket value for profile_resume_editor_schema_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_resume_editor_schema_tstValue_;




  /**
   * Checks if the user is in profile_resume_editor_schema_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_resume_editor_schema_tstInactive = function() {
    return this.profile_resume_editor_schema_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_resume_editor_schema_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_resume_editor_schema_tstControl = function() {
    return this.profile_resume_editor_schema_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_resume_editor_schema_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_resume_editor_schema_tstActive = function() {
    return this.profile_resume_editor_schema_tstValue_ === 1;
  };



  // PROFILE_SEGMENTATION_PREFERENCE_TOG


  /**
   * Bucket value for profile_segmentation_preference_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_segmentation_preference_togValue_;




  /**
   * Checks if the user is in profile_segmentation_preference_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_segmentation_preference_togInactive = function() {
    return this.profile_segmentation_preference_togValue_ === -1;
  };


  /**
   * Checks if the user is in profile_segmentation_preference_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_segmentation_preference_togControl = function() {
    return this.profile_segmentation_preference_togValue_ === 0;
  };


  /**
   * Checks if the user is in profile_segmentation_preference_togTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_segmentation_preference_togTest = function() {
    return this.profile_segmentation_preference_togValue_ === 1;
  };



  // PROFILE_TEXT_EXPERIENCE_INPUT_TST


  /**
   * Bucket value for profile_text_experience_input_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_text_experience_input_tstValue_;




  /**
   * Checks if the user is in profile_text_experience_input_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_text_experience_input_tstInactive = function() {
    return this.profile_text_experience_input_tstValue_ === -1;
  };


  /**
   * Checks if the user is in profile_text_experience_input_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_text_experience_input_tstControl = function() {
    return this.profile_text_experience_input_tstValue_ === 0;
  };


  /**
   * Checks if the user is in profile_text_experience_input_tstActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_text_experience_input_tstActive = function() {
    return this.profile_text_experience_input_tstValue_ === 1;
  };



  // PROFILE_UPDATE_APP_BANNER_TOG


  /**
   * Bucket value for profile_update_app_banner_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profile_update_app_banner_togValue_;




  /**
   * Checks if the user is in profile_update_app_banner_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_update_app_banner_togInactive = function() {
    return this.profile_update_app_banner_togValue_ === -1;
  };


  /**
   * Checks if the user is in profile_update_app_banner_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_update_app_banner_togControl = function() {
    return this.profile_update_app_banner_togValue_ === 0;
  };


  /**
   * Checks if the user is in profile_update_app_banner_togTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfile_update_app_banner_togTest = function() {
    return this.profile_update_app_banner_togValue_ === 1;
  };



  // PROFILENOSPLASHWHENNAME


  /**
   * Bucket value for profilenosplashwhenname.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.profilenosplashwhennameValue_;




  /**
   * Checks if the user is in profilenosplashwhennameInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfilenosplashwhennameInactive = function() {
    return this.profilenosplashwhennameValue_ === -1;
  };


  /**
   * Checks if the user is in profilenosplashwhennameControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfilenosplashwhennameControl = function() {
    return this.profilenosplashwhennameValue_ === 0;
  };


  /**
   * Checks if the user is in profilenosplashwhennameGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isProfilenosplashwhennameGrp1 = function() {
    return this.profilenosplashwhennameValue_ === 1;
  };



  // RAVEN_US_EPAS_PROFILE_ENRICHMENT_EML_TST_1_MVTST


  /**
   * Bucket value for raven_us_epas_profile_enrichment_eml_tst_1_mvtst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_;




  /**
   * Checks if the user is in raven_us_epas_profile_enrichment_eml_tst_1_mvtstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRaven_us_epas_profile_enrichment_eml_tst_1_mvtstInactive = function() {
    return this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ === -1;
  };


  /**
   * Checks if the user is in raven_us_epas_profile_enrichment_eml_tst_1_mvtstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRaven_us_epas_profile_enrichment_eml_tst_1_mvtstControl = function() {
    return this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ === 0;
  };


  /**
   * Checks if the user is in raven_us_epas_profile_enrichment_eml_tst_1_mvtstGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRaven_us_epas_profile_enrichment_eml_tst_1_mvtstGrp1 = function() {
    return this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ === 1;
  };


  /**
   * Checks if the user is in raven_us_epas_profile_enrichment_eml_tst_1_mvtstGrp2.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRaven_us_epas_profile_enrichment_eml_tst_1_mvtstGrp2 = function() {
    return this.raven_us_epas_profile_enrichment_eml_tst_1_mvtstValue_ === 2;
  };



  // RE_UPLOAD_TO_REPLACE


  /**
   * Bucket value for re_upload_to_replace.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.re_upload_to_replaceValue_;




  /**
   * Checks if the user is in re_upload_to_replaceInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRe_upload_to_replaceInactive = function() {
    return this.re_upload_to_replaceValue_ === -1;
  };


  /**
   * Checks if the user is in re_upload_to_replaceControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRe_upload_to_replaceControl = function() {
    return this.re_upload_to_replaceValue_ === 0;
  };


  /**
   * Checks if the user is in re_upload_to_replaceTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isRe_upload_to_replaceTest = function() {
    return this.re_upload_to_replaceValue_ === 1;
  };



  // SEGMENT_SELECTION_INDIA_TST


  /**
   * Bucket value for segment_selection_india_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.segment_selection_india_tstValue_;




  /**
   * Checks if the user is in segment_selection_india_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSegment_selection_india_tstInactive = function() {
    return this.segment_selection_india_tstValue_ === -1;
  };


  /**
   * Checks if the user is in segment_selection_india_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSegment_selection_india_tstControl = function() {
    return this.segment_selection_india_tstValue_ === 0;
  };


  /**
   * Checks if the user is in segment_selection_india_tstGrp1.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSegment_selection_india_tstGrp1 = function() {
    return this.segment_selection_india_tstValue_ === 1;
  };



  // SI_PROFILE_EDUCATION_DESCRIPTION_INITIALLY_HIDDEN_TST


  /**
   * Bucket value for si_profile_education_description_initially_hidden_tst.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.si_profile_education_description_initially_hidden_tstValue_;




  /**
   * Checks if the user is in si_profile_education_description_initially_hidden_tstInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSi_profile_education_description_initially_hidden_tstInactive = function() {
    return this.si_profile_education_description_initially_hidden_tstValue_ === -1;
  };


  /**
   * Checks if the user is in si_profile_education_description_initially_hidden_tstControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSi_profile_education_description_initially_hidden_tstControl = function() {
    return this.si_profile_education_description_initially_hidden_tstValue_ === 0;
  };


  /**
   * Checks if the user is in si_profile_education_description_initially_hidden_tstTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSi_profile_education_description_initially_hidden_tstTest = function() {
    return this.si_profile_education_description_initially_hidden_tstValue_ === 1;
  };



  // SUGGESTIONS_UI_UPDATE_FLOWS


  /**
   * Bucket value for suggestions_ui_update_flows.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.suggestions_ui_update_flowsValue_;




  /**
   * Checks if the user is in suggestions_ui_update_flowsInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSuggestions_ui_update_flowsInactive = function() {
    return this.suggestions_ui_update_flowsValue_ === -1;
  };


  /**
   * Checks if the user is in suggestions_ui_update_flowsControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSuggestions_ui_update_flowsControl = function() {
    return this.suggestions_ui_update_flowsValue_ === 0;
  };


  /**
   * Checks if the user is in suggestions_ui_update_flowsTest.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isSuggestions_ui_update_flowsTest = function() {
    return this.suggestions_ui_update_flowsValue_ === 1;
  };



  // TITLE_MATCH_AUTOCOMPLETE_LOGGING_TOG


  /**
   * Bucket value for title_match_autocomplete_logging_tog.
   * @type {number}
   * @private
   */
  ProfileProctorGroups_.prototype.title_match_autocomplete_logging_togValue_;




  /**
   * Checks if the user is in title_match_autocomplete_logging_togInactive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isTitle_match_autocomplete_logging_togInactive = function() {
    return this.title_match_autocomplete_logging_togValue_ === -1;
  };


  /**
   * Checks if the user is in title_match_autocomplete_logging_togControl.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isTitle_match_autocomplete_logging_togControl = function() {
    return this.title_match_autocomplete_logging_togValue_ === 0;
  };


  /**
   * Checks if the user is in title_match_autocomplete_logging_togActive.
   * @return {boolean}
   */
  ProfileProctorGroups_.prototype.isTitle_match_autocomplete_logging_togActive = function() {
    return this.title_match_autocomplete_logging_togValue_ === 1;
  };



  /**
  * Static reference to instance of groups class.
  *
  * @type {ProfileProctorGroups_}
  * @private
  */
  var groups_ = null;

  return {

    /**
    * Create an instance of ProfileProctorGroups_.
    * Assign it to a static reference and return it.
    *
    * @param {Array.<Object>} values a list of integers that matches proctor test groups for the request.
    * @return {ProfileProctorGroups_}
    */
    init: function(values) {
      groups_ = new ProfileProctorGroups_(values);
      return groups_;
    },


    /**
    * Get the static reference to our groups instance.
    * If it has not been initialized, return a default instance.
    *
    * @return {ProfileProctorGroups_}
    */
    getGroups: function() {
      if (groups_ == null) {
        groups_ = new ProfileProctorGroups_();
      }
      return groups_;
    }

  };
}));
